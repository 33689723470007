import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';

export default function ArchiveTraffic({
  open,
  initialData,
  handleClose,
  onSubmit,
  showArchive,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {showArchive ? 'Unarchive Offers' : 'Archive Offers'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Do you want to {showArchive ? 'Unarchive' : 'Archive'} the selected
          offers?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        {showArchive ? (
          <Button
            variant="contained"
            onClick={() => onSubmit(initialData, false, handleClose(true))}
            color="secondary"
          >
            Unarchive
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={() => onSubmit(initialData, true, handleClose(true))}
            color="primary"
          >
            Archive
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
