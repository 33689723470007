import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { getAxiosErrorMessage } from '../../../lib';
import useTraffic from '../useTraffic';
import { BlockRule, TrafficSource } from '../types';
import RuleForm from '../../../components/RuleForm';

type Props = {};

const SingleTrafficBlock = (props: Props) => {
  const [trafficData, setTrafficData] = useState<TrafficSource | null>(null);
  const [loading, setLoading] = useState(false);

  const { getSingleTraffic } = useTraffic();

  const params = useParams();
  const navigate = useNavigate();
  const client = useTraffic();

  const init = (id: string) => {
    setLoading(true);
    const apiCall = getSingleTraffic();

    (async function () {
      try {
        const singleTraffic: TrafficSource = await apiCall.call(id);
        setTrafficData(singleTraffic);
      } catch (error) {
        console.error(getAxiosErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    if (!params.sourceId) return;

    return init(params.sourceId);
  }, [params.sourceId]);

  const editRule = async (rules: BlockRule[]) => {
    if (!trafficData) return;
    const params = { ...trafficData, blockRules: rules };
    await client.editTraffic().call(trafficData._id, params);

    setTrafficData(params);
  };

  const onDelete = async (rules: BlockRule[]) => {
    if (!trafficData) return;

    const newTraffic = { ...trafficData, blockRules: rules };

    await client.editTraffic().call(trafficData._id, newTraffic);

    setTrafficData(newTraffic);
  };

  const addEmptyRule = async (newRule: BlockRule) => {
    if (!trafficData) return;

    const flatRules = [trafficData.blockRules].flat();
    flatRules.push(newRule);

    const params = { ...trafficData, blockRules: flatRules };
    await client.editTraffic().call(trafficData._id, params);

    setTrafficData(params);
  };

  return (
    <Box my={4}>
      <Box mb={3}>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <Button startIcon={<ArrowBack />}>Back</Button>
        </Link>
      </Box>
      <Typography variant="h4" gutterBottom>
        Block Rules
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <RuleForm
          parentRules={trafficData?.blockRules ?? []}
          onDelete={onDelete}
          onSubmit={editRule}
          onAddNewRule={addEmptyRule}
        />
      )}
    </Box>
  );
};

export default SingleTrafficBlock;
