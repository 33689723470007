import React from 'react';
import CampaignTable from './CampaignTable';
import OfferProvider from '../offer/context';

const Campaign: React.FC = () => {
  return (
    <OfferProvider>
      <CampaignTable />
    </OfferProvider>
  );
};

export default Campaign;
