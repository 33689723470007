import { Box, Grid, Paper, Typography, Button, Chip } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import IronDomeMemberSelector from './IronDomeMemberSelector';
import IronDomeProvider, { IronDomeContext } from './context';
import useOffer from '../offer/useOffer';
import { BlockRule } from '../traffic/types';
import RuleForm from '../../components/RuleForm';
import CloneOfferDialog from './ironDomeClone/CloneOfferDialog';
import { nanoid } from 'nanoid';

const IronDomeContainer = () => {
  const { selectedMembers, setOffers, offers, setSelectedMembers } =
    useContext(IronDomeContext);
  const offerClient = useOffer();
  const [parentRules, setParentRules] = useState<BlockRule[]>([]);
  const [bulkRules, setBulkRules] = useState<BlockRule[]>([]);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<any[]>([]);

  useEffect(() => {
    setIsBulkEditing(false);
    setBulkRules([]);

    const flattenedRules = selectedOffer.flatMap((member) => {
      let blockRulesArray: BlockRule[] = [];

      if (Array.isArray(member.blockRules)) {
        blockRulesArray = member.blockRules;
      } else if (
        typeof member.blockRules === 'object' &&
        member.blockRules !== null
      ) {
        blockRulesArray = [member.blockRules];
      }

      return blockRulesArray;
    });

    setParentRules(flattenedRules);
  }, [selectedOffer]);

  const updateOffersState = (updatedOffers: any[]) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) => {
        const updatedOffer = updatedOffers.find(
          (u: any) => u._id === offer._id
        );
        return updatedOffer ? { ...offer, ...updatedOffer } : offer;
      })
    );

    setSelectedOffer((prevMembers: any[]) =>
      prevMembers.map((member) => {
        const updatedMember = updatedOffers.find(
          (u: any) => u._id === member._id
        );
        return updatedMember ? updatedMember : member;
      })
    );
  };

  const editRule = async (updatedRules: BlockRule[]) => {
    try {
      const updatedOffers = selectedOffer.map((member) => {
        let existingRules: BlockRule[] = [];
        if (Array.isArray(member.blockRules)) {
          existingRules = member.blockRules;
        } else if (
          typeof member.blockRules === 'object' &&
          member.blockRules !== null
        ) {
          existingRules = [member.blockRules as BlockRule];
        }

        return {
          ...member,
          blockRules: updatedRules,
        };
      });

      const offerIds = selectedOffer.map((m) => m._id);
      const editFields = Object.fromEntries(
        updatedOffers.map((offer) => [
          offer._id,
          { blockRules: offer.blockRules },
        ])
      );

      await (offerIds.length === 1
        ? offerClient
            .editOffer()
            .call({ offerId: offerIds[0], editField: editFields[offerIds[0]] })
        : offerClient.bulkEditRules().call({ offerIds, editFields }));

      setOffers((prev) =>
        prev.map((o) => updatedOffers.find((u: any) => u._id === o._id) || o)
      );
      setSelectedOffer(updatedOffers);
    } catch (error) {
      console.error('Error editing rules:', error);
      throw error;
    }
  };

  const onDelete = async (newRules: BlockRule[]) => {
    try {
      if (isBulkEditing) {
        setBulkRules(newRules);
      } else {
        await editRule(newRules);
        const updatedOffers = selectedOffer.map((member) => ({
          ...member,
          blockRules: newRules,
        }));

        setOffers((prevOffers) =>
          prevOffers.map((offer) => {
            const updatedOffer = updatedOffers.find(
              (u: any) => u._id === offer._id
            );
            return updatedOffer ? updatedOffer : offer;
          })
        );

        setSelectedOffer(updatedOffers);
      }
    } catch (error) {
      console.error('Error deleting rules:', error);
      throw error;
    }
  };

  const addEmptyRule = async (newRule: BlockRule) => {
    if (isBulkEditing) {
      setBulkRules((prev) => [...prev, { ...newRule, id: nanoid() }]);
    } else {
      const updatedOffers = selectedOffer.map((member) => {
        let memberRules: BlockRule[] = [];
        if (Array.isArray(member.blockRules)) {
          memberRules = member.blockRules;
        } else if (
          typeof member.blockRules === 'object' &&
          member.blockRules !== null
        ) {
          memberRules = [member.blockRules as BlockRule];
        }

        return {
          ...member,
          blockRules: [...memberRules, { ...newRule, id: nanoid() }],
        };
      });

      await offerClient.editOffer().call({
        offerId: selectedOffer[0]._id,
        editField: { blockRules: updatedOffers[0].blockRules },
      });

      updateOffersState(updatedOffers);
    }
  };

  const handleBulkEdit = () => {
    setIsBulkEditing(true);
    setBulkRules([]);
  };

  const handleBulkSave = async () => {
    try {
      const updatedOffers = selectedOffer.map((member) => {
        const newRulesForThisOffer = bulkRules.map((rule) => ({
          ...rule,
          id: nanoid(),
        }));
        return {
          ...member,
          blockRules: newRulesForThisOffer,
        };
      });

      const offerIds = selectedOffer.map((m) => m._id);
      const editFields = Object.fromEntries(
        updatedOffers.map((offer) => [
          offer._id,
          { blockRules: offer.blockRules },
        ])
      );

      await offerClient.bulkEditRules().call({ offerIds, editFields });

      setOffers((prev) =>
        prev.map((o) => updatedOffers.find((u: any) => u._id === o._id) || o)
      );
      setSelectedOffer(updatedOffers);

      setIsBulkEditing(false);
      setBulkRules([]);
    } catch (error) {
      console.error('Error saving bulk rules:', error);
      alert('Failed to save rules. Please try again.');
    }
  };

  const handleCancelBulkEdit = () => {
    setIsBulkEditing(false);
    setBulkRules([]);
  };

  const handleCloneOffers = (updatedOffers: any[]) => {
    setOffers((prevOffers) =>
      prevOffers.map((offer) => {
        const updatedOffer = updatedOffers.find(
          (u: any) => u._id === offer._id
        );
        return updatedOffer ? updatedOffer : offer;
      })
    );

    setSelectedOffer((prevMembers: any[]) =>
      prevMembers.map((member) => {
        const updatedMember = updatedOffers.find(
          (u: any) => u._id === member._id
        );
        return updatedMember ? updatedMember : member;
      })
    );
  };

  const onSelectOfferDone = (selectedOffers: any[]) => {
    setSelectedOffer(selectedOffers);
  };

  return (
    <Grid container sx={{ mt: 1 }} spacing={2}>
      <IronDomeMemberSelector onSelectOfferDone={onSelectOfferDone} />
      <Grid item xs={10}>
        {selectedOffer.length > 0 ? (
          <Paper>
            <Box p={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ mb: 3, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {selectedOffer.length === offers.length ? (
                    <Chip label="All" color="success" />
                  ) : (
                    selectedOffer.map((member) => (
                      <Chip key={member._id} label={member.name} />
                    ))
                  )}
                </Box>
                <Box display="flex" gap={1}>
                  {selectedOffer.length > 1 && !isBulkEditing && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: 180 }}
                      onClick={handleBulkEdit}
                    >
                      BULK MODIFY RULES
                    </Button>
                  )}
                  {isBulkEditing && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBulkSave}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancelBulkEdit}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              {isBulkEditing ? (
                <RuleForm
                  onDelete={onDelete}
                  onSubmit={(rules: BlockRule[]) => setBulkRules(rules)}
                  parentRules={bulkRules}
                  onAddNewRule={addEmptyRule}
                />
              ) : selectedOffer.length === 1 ? (
                <RuleForm
                  onDelete={onDelete}
                  onSubmit={editRule}
                  parentRules={parentRules}
                  onAddNewRule={addEmptyRule}
                  onClone={() => setCloneDialogOpen(true)}
                />
              ) : null}
            </Box>
          </Paper>
        ) : (
          'No selected offer'
        )}
      </Grid>
      <CloneOfferDialog
        open={cloneDialogOpen}
        onClose={() => setCloneDialogOpen(false)}
        onClone={handleCloneOffers}
        sourceOffer={selectedOffer[0]}
      />
    </Grid>
  );
};

const IronDomePage = () => {
  return (
    <IronDomeProvider>
      <IronDomeContainer />
    </IronDomeProvider>
  );
};

export default IronDomePage;
