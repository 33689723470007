import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DateFilter from '../../../components/DateFilter';
import { Range } from 'react-date-range';
import Download from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import DatePicker from '../../../components/DatePicker';
import { toTwoDecimals } from '../../../lib';

type Props = {
  onDateFilterSubmit: (range: Range) => void;
  initialDateRange: Range;
  actualClicks: any[];
  rows: any[];
  customOfferLabel: string;
};

const OfferGroupClicksTableToolbar: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
  actualClicks,
  rows,
  customOfferLabel,
}) => {
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [openDialog, setOpenDialog] = useState<string | null>(null);

  const handleDatePickerSubmit = (newDate: Date) => {
    const range = { startDate: newDate, endDate: newDate };
    setDateRange(range);
    onDateFilterSubmit(range);
  };

  const handleDownloadReport = () => {
    const csvHeaders = ['Date', 'Click Id', 'Commission'];

    const csvRows = actualClicks.map((row) => {
      const date = moment(row.date).format('YYYY-MM-DD HH:mm:ss');
      const clickId = row.clickid || '';
      const commission = row.commission || 0;

      return [date, clickId, commission];
    });

    const csvContent = [csvHeaders, ...csvRows]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'actual_report.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownloadClickTable = () => {
    const csvHeaders = ['M. Id', 'M. Name', 'Reported Clicks', 'Actual Clicks', 'Clk. diff. %', 'Reported Revenue', 'Actual Revenue', 'Rev. diff. %', 'Avg. CPC'];

    const escapeValue = (value: any) => {
      if (typeof value === 'string' && value.includes(',')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value;
    };

    const csvRows = rows.map((row) => {
      const merchantId = escapeValue(row?.merchantId);
      const merchantName = escapeValue(row?.merchantName);
      const reportedClicks = escapeValue(row?.reportedClicks);
      const actualClicks = escapeValue(row?.actualClicks);
      const clickDifferenceValue = actualClicks <= 0 ? 0 : 1 - reportedClicks / actualClicks;
      const clickDiff = toTwoDecimals(clickDifferenceValue * 100);
      const clickDifference = escapeValue(`${clickDiff}%`);
      const reportedRevenueValue = toTwoDecimals(row?.reportedRevenue);
      const reportedRevenue = escapeValue(`$${reportedRevenueValue}`);
      const actualRevenueValue = toTwoDecimals(row?.actualRevenue);
      const actualRevenue = escapeValue(`$${actualRevenueValue}`);
      const revenueDifferenceValue = row.actualRevenue <= 0 ? 0 : 1 - row?.reportedRevenue / row.actualRevenue;
      const revenueDiff = toTwoDecimals(revenueDifferenceValue * 100);
      const revenueDifference = escapeValue(`${revenueDiff}%`);
      const revenue = (row?.actualRevenue || 0) + (row?.reportedRevenue || 0);
      const clicks = (row?.reportedClicks || 0) + (row?.actualClicks || 0);
      const avgCpc = clicks !== 0 ? revenue / clicks : 0;
      const avgCpcValue = toTwoDecimals(avgCpc);
      const averageCpc = escapeValue(`$${avgCpcValue}`);

      return [
        merchantId,
        merchantName,
        reportedClicks,
        actualClicks,
        clickDifference,
        reportedRevenue,
        actualRevenue,
        revenueDifference,
        averageCpc,
      ];
    });

    const csvContent = [csvHeaders, ...csvRows]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${customOfferLabel}-clicks-${moment(dateRange?.startDate).format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const modalButtons = [
    {
      name: 'filter',
      label: 'Date Filter',
      renderIf: true,
      icon: <FilterAltIcon />,
      render: (open: boolean, handleClose: () => any) => (
        <DatePicker
          open={open}
          handleClose={handleClose}
          onSubmit={handleDatePickerSubmit}
          initialData={dateRange.startDate}
        />
      ),
    },
    {
      name: 'download',
      label: 'Download Actual Report',
      renderIf: true,
      icon: <Download />,
      onClick: handleDownloadReport,
      type: 'button',
    },
    {
      name: 'downloadTable',
      label: 'Download Table Data',
      renderIf: true,
      icon: <Download />,
      onClick: handleDownloadClickTable,
      type: 'button',
    },
  ];

  return (
    <>
      {modalButtons.map((button) => (
        <React.Fragment key={button.name}>
          <Button
            startIcon={button.icon}
            onClick={() => {
              if (button.name === 'download' || button.name === 'downloadTable') {
                button.onClick && button.onClick();
              } else {
                setOpenDialog(button.name);
              }
            }}
          >
            {button.label}
          </Button>
          {button.name !== 'download' && button.name !== 'downloadTable' && (
            <Dialog open={openDialog === button.name} onClose={() => setOpenDialog(null)}>
              <DialogTitle>{button.label}</DialogTitle>
              <DialogContent>
                {button.render && button.render(openDialog === button.name, () => setOpenDialog(null))}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(null)}>Close</Button>
                {button.type === 'button' && (
                  <Button onClick={button.onClick} color="primary">
                    {button.label}
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default OfferGroupClicksTableToolbar;