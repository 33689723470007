import useApi from '../../../hooks/useApi';

const API_URL = '/api/campaign-offers';

export default function useCampaignOffer() {
  const { api: apiClient, createApiCall } = useApi();

  const getCampaignOffers = createApiCall(async ({ signal }, campaignId) => {
    if (!campaignId) {
      throw new Error('campaignId is required to fetch campaign offers');
    }

    const {
      data: { campaignOffers },
    } = await apiClient.get(`${API_URL}?campaignId=${campaignId}`, { signal });

    return campaignOffers;
  });

  const editCampaignOffer = createApiCall(
    async ({ signal }, id, newCampaignOffer) => {
      const {
        data: { campaignOffer },
      } = await apiClient.put(`${API_URL}/${id}`, newCampaignOffer, { signal });

      return campaignOffer;
    }
  );

  const deleteCampaignOffer = createApiCall(async ({ signal }, id) => {
    await apiClient.delete(`${API_URL}/${id}`, { signal });
  });

  const addCampaignOffer = createApiCall(
    async ({ signal }, newCampaignOffer) => {
      const {
        data: { campaignOffer },
      } = await apiClient.post(API_URL, newCampaignOffer, { signal });
      return campaignOffer;
    }
  );

  return {
    addCampaignOffer,
    getCampaignOffers,
    editCampaignOffer,
    deleteCampaignOffer,
  };
}
