import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface UploadDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
  error?: string | null;
  clearError?: () => void;
}

const UploadDialog: React.FC<UploadDialogProps> = ({
  open,
  onClose,
  onSubmit,
  error,
  clearError,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (open) {
      setSelectedFile(null);
      if (clearError) {
        clearError();
      }
    }
  }, [open, clearError]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      if (clearError) {
        clearError();
      }
    }
  };

  const handleSubmit = () => {
    if (selectedFile) {
      onSubmit(selectedFile);
      setSelectedFile(null);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Upload File
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error.split('\n').map((line, index) => (
              <div key={index}>
                <small>{line}</small>
              </div>
            ))}
          </Alert>
        )}
        <input type="file" onChange={handleFileChange} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
