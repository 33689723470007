import useApi from '../../hooks/useApi';
const API_URL_CLICKS = '/api/clicks';
const API_URL_REQUEST = '/api/request';

export default function useClicks() {
  const { api: apiClient, createApiCall } = useApi();

  const getClicks = createApiCall(
    async (
      { signal },
      startDate,
      endDate,
      page,
      pageSize,
      sort,
      sortColumn,
    ) => {
      const { data: clicks } = await apiClient.post(
        API_URL_CLICKS,
        { startDate, endDate, page, pageSize, sort, sortColumn },
        { signal }
      );

      return clicks;
    }
  );

  const getClicksRequest = createApiCall(
    async ({ signal }, startDate, endDate, groupBy) => {
      const { data: clicks } = await apiClient.get(`${API_URL_REQUEST}/stats`, {
        signal,
        params: {
          startDate,
          endDate,
          groupBy,
        },
      });
      return clicks;
    }
  );

  return { getClicks, getClicksRequest };
}
