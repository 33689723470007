import React, { useState, useMemo, useEffect, memo } from 'react';
import { Card, Box, Chip, FormControlLabel, Switch } from '@mui/material';
import useTraffic from '../useTraffic';
import TrafficToolbar from './Toolbar';
import { useNavigate, Link } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import TooltipIconButton from '../../../components/TooltipIconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SettingsIcon from '@mui/icons-material/Settings';
import { DisabledByDefault } from '@mui/icons-material';
import QuickFilter from '../../../components/QuickFilter';
import DynamicTable from '../../../components/DynamicAGGrid';
import { showMessage } from '../../../components/utils/showMessage';

function TrafficTable() {
  const [traffic, setTraffic] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quickFilter, setQuickFilter] = useState('');
  const { getTraffic, deleteTraffic, editTraffic } = useTraffic();
  const [showArchive, setShowArchive] = useState(false);
  const [finalTraffic, setFinalTraffic] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getTraffic().call();
      setTraffic(response);
    } finally {
      setLoading(false);
    }
  };

  const updateTrafficArchiveStatus = async (id, newArchiveStatus) => {
    try {
      await editTraffic().call(id, { archive: newArchiveStatus });
      showMessage({
        message: 'Traffic entry updated successfully!',
        severity: 'success',
      });
      setTraffic((prevTraffic) =>
        prevTraffic.map((item) =>
          item._id === id ? { ...item, archive: newArchiveStatus } : item
        )
      );
    } catch (error) {
      showMessage({
        message: 'Error updating traffic entry.',
        severity: 'error',
      });
      console.error('Error editing traffic:', error);
    }
  };

  const onAddTrafficDone = (newTraffic) => {
    setTraffic((prevTraffic) => [...prevTraffic, newTraffic]);
  };

  const onEditTrafficDone = (updatedTraffic) => {
    setTraffic((prevTraffic) =>
      prevTraffic.map((item) =>
        item._id === updatedTraffic._id ? updatedTraffic : item
      )
    );
  };

  const onDeleteTrafficDone = (id) => {
    setTraffic((prevTraffic) =>
      prevTraffic.filter((item) => !id.includes(item._id))
    );
  };

  const onBulkArchiveDone = (updatedRows) => {
    setTraffic((prevTraffic) =>
      prevTraffic.map((item) =>
        updatedRows.find((updated) => updated._id === item._id)
          ? {
              ...item,
              archive: updatedRows.find((updated) => updated._id === item._id)
                .archive,
            }
          : item
      )
    );
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await deleteTraffic().call(id);
      setTraffic((prevTraffic) =>
        prevTraffic.filter((item) => item._id !== id)
      );
    } finally {
      setLoading(false);
    }
  };

  const renderCell = (type, data) => {
    switch (type) {
      case 'name':
        return <Link to={data._id}>{data.name}</Link>;

      case 'parameterOptions':
        return data.parameterOptions.length ? (
          data.parameterOptions.map((item) => (
            <Chip label={item.tag} sx={{ mr: 1 }} key={item.tag} />
          ))
        ) : (
          <Chip label="No parameters" />
        );

      case 'archive':
        return (
          <Chip
            label={`${data.archive}`}
            color={data.archive ? 'success' : 'error'}
          />
        );

      case 'actions':
        return (
          <Box>
            <TooltipIconButton
              onClick={() => navigate(data._id)}
              title="View"
              icon={<SettingsIcon />}
            />
            <TooltipIconButton
              onClick={() => navigate(`${data._id}/block`)}
              title="Block rules"
              icon={<DisabledByDefault />}
            />
            <TooltipIconButton
              icon={data.archive ? <UnarchiveIcon /> : <ArchiveIcon />}
              title={data.archive ? 'Unarchive' : 'Archive'}
              onClick={() =>
                updateTrafficArchiveStatus(data._id, !data.archive)
              }
              size="small"
            />
          </Box>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    let filtered = traffic.filter((item) => {
      const lowercasedFilter = quickFilter.toLowerCase();

      const nameMatches =
        item.name?.toLowerCase().includes(lowercasedFilter) ?? false;

      const parametersMatch =
        item.parameterOptions?.some((param) =>
          param?.tag?.toLowerCase().includes(lowercasedFilter)
        ) ?? false;

      return nameMatches || parametersMatch;
    });

    setFinalTraffic(
      showArchive ? filtered : filtered.filter((item) => !item.archive)
    );
  }, [traffic, quickFilter, showArchive]);

  return (
    <Box mt={4}>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <QuickFilter
            quickFilter={quickFilter}
            setQuickFilter={setQuickFilter}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showArchive}
                onChange={(e) => setShowArchive(e.target.checked)}
                name="showArchived"
                color="primary"
              />
            }
            label="Show Archived"
          />
        </Box>
        <TrafficToolbar
          selectedRow={traffic.find((item) => selectedRows.includes(item._id))}
          onAddTrafficDone={onAddTrafficDone}
          onEditTrafficDone={onEditTrafficDone}
          onDeleteTrafficDone={onDeleteTrafficDone}
          onBulkArchiveDone={onBulkArchiveDone}
          rowToDelete={selectedRows}
          rowToArchive={selectedRows}
          showArchive={showArchive}
          currentData={traffic}
        />
        <DynamicTable
          columns={generateTableCols()}
          filteredData={finalTraffic}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          renderCell={renderCell}
        />
      </Card>
    </Box>
  );
}

const generateTableCols = () => [
  { name: 'Name', field: 'name', flex: 1, filter: false },
  { name: 'Parameters', field: 'parameterOptions', flex: 2, filter: false },
  { name: 'Archive', field: 'archive', flex: 2, filter: false },
  { name: 'Actions', field: 'actions', flex: 1, filter: false },
];

export default memo(TrafficTable);
