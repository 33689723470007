import React, { useState } from 'react';
import { Range, DateRangePicker } from 'react-date-range';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

type Props = {
  onDateFilterSubmit: (ranges: Range[]) => void;
  initialDateRange: Range;
  onExportData: () => Promise<any[]>;
};

const RequestTableToolbar: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
  onExportData, 
}) => {
  const [tempDateRange, setTempDateRange] = useState(initialDateRange);
  const [openFilter, setOpenFilter] = useState(false);

  const handleDatePickerChange = (ranges: any) => {
    const selectedRange = ranges.selection;
    const start = moment(selectedRange.startDate);
    const end = moment(selectedRange.endDate);
    const totalDays = end.diff(start, 'days') + 1;

    if (totalDays <= 4) {
      setTempDateRange(selectedRange);
    } else {
      console.warn("You can select up to 4 days only.");
    }
  };

  const handleApplyDateRange = () => {
    onDateFilterSubmit([tempDateRange]);
    setOpenFilter(false);
  };

  const handleExportCSV = async () => {
    const data = await onExportData();

    if (!data || data.length === 0) {
      console.warn("No data available for export.");
      return;
    }

    const formattedData = data.map((item) => ({
      Date: moment.utc(item.createdAt).format('YYYY-MM-DD HH:mm:ss') + ' UTC',
      IP: item.request?.ip || '',
      Offer: item.offerDetails?.name || '',
      'Traffic Source': item.trafficDetails?.name || '',
      Domain: item.domainDetails?.domainLink || '',
      'Blocked Reason': item.blockingRules
        ?.map((rule: any) => {
          return Object.keys(rule.rule || {}).filter((key) => rule.rule[key]);
        })
        .flat()
        .join(', ') || '',
    }));

    const nameStartDate = moment(initialDateRange?.startDate).utc().format('YYYY-MM-DD');
    const nameEndDate = moment(initialDateRange?.endDate).utc().format('YYYY-MM-DD');

    const csv = Papa.unparse(formattedData, {
      quotes: true,
      delimiter: ",",
      header: true,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `requestlogs-${nameStartDate}-${nameEndDate}.csv`);
  };

  return (
    <Box display="flex" alignItems="center" pb={2} bgcolor="background.paper">
      <Button
        color="primary"
        startIcon={<FilterAltIcon />}
        onClick={() => setOpenFilter(true)}
      >
        Date Filter
      </Button>

      <Button 
        color="primary" 
        startIcon={<FileDownloadIcon />}
        onClick={handleExportCSV}
      >
        Export CSV
      </Button>

      <Dialog open={openFilter} onClose={() => setOpenFilter(false)}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <DateRangePicker
            ranges={[tempDateRange]}
            onChange={handleDatePickerChange}
            maxDate={new Date()}
            months={1}
            direction="horizontal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFilter(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyDateRange} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RequestTableToolbar;