import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router-dom';
import { CustomOffer, CustomOfferGroup } from './types';
import useCustomOffer from './useCustomOffers';
import useTraffic from '../traffic/useTraffic';
import { TrafficSource } from '../traffic/types';

type Props = {
  children: React.ReactNode;
};

export type MerchantData = {
  merchantName: string;
  merchantId: any;
  _id: string;
};

type OfferGroupContextValues = {
  offerGroup: CustomOfferGroup | null;
  loading: boolean;
  customOffers: CustomOffer[];
  setCustomOffers: React.Dispatch<React.SetStateAction<CustomOffer[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  traffic: TrafficSource[];
  setTraffic: React.Dispatch<React.SetStateAction<TrafficSource[]>>;
  setOfferGroup: React.Dispatch<React.SetStateAction<CustomOfferGroup | null>>;
  merchants: MerchantData[];
  init: () => void; // Expose the init function
};

export const OfferGroupContext = createContext<OfferGroupContextValues>(
  {} as any
);

function OfferGroupProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);

  const [offerGroup, setOfferGroup] = useState<CustomOfferGroup | null>(null);
  const [customOffers, setCustomOffers] = useState<CustomOffer[]>([]);
  const [merchants, setMerchants] = useState<MerchantData[]>([]);

  const [traffic, setTraffic] = useState<TrafficSource[]>([]);

  const params = useParams();
  const client = useCustomOffer();
  const trafficClient = useTraffic();

  const init = () => {
    const api = client.getOfferGroup();
    const api2 = client.getCustomOffers();

    (async () => {
      try {
        setLoading(true);
        const res = await api.call(params.id);
        setOfferGroup(res);

        const off = await api2.call(params.id);
        setCustomOffers(off);

        const mdata = off.map((i: CustomOffer) => ({
          _id: i._id,
          merchantName: i.data.merchantName,
          merchantId: i.data.merchantId,
        }));

        setMerchants(mdata);
      } catch (error) {}
      setLoading(false);
    })();

    return () => {
      api.abort();
      api2.abort();
    };
  };

  const getGroupSources = () => {
    if (!offerGroup) return;

    const ids = offerGroup.trafficSources.map((o) => o.id);
    const api = trafficClient.getTrafficByIds();

    api.call(ids).then((res) => setTraffic(res));

    return api.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  useEffect(() => {
    return getGroupSources();
  }, [offerGroup]);

  const values = {
    offerGroup,
    loading,
    customOffers,
    setCustomOffers,
    setLoading,
    traffic,
    setTraffic,
    setOfferGroup,
    merchants,
    init, // Include the init function in the context values
  };

  return (
    <OfferGroupContext.Provider value={values}>
      {children}
    </OfferGroupContext.Provider>
  );
}

export default OfferGroupProvider;
