import React, { useContext, useState } from 'react';
import {
  Box,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Divider,
} from '@mui/material';
import { CustomOffer, CustomOfferGroup } from '../types';
import LoadingButton from '../../../components/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import useOffer from '../../offer/useOffer';
import { OfferGroupContext } from '../context';
import { useParams } from 'react-router-dom';
import useCustomOffer from '../useCustomOffers';
import { showMessage } from '../../../components/utils/showMessage';
import { checkField, preSubmitValidation } from './utils';

dayjs.extend(customParseFormat);

type Props = {
  handleClose: () => any;
  // row: CustomOffer;
  group: CustomOfferGroup;
  onSubmit: (id: string, newRow: Pick<CustomOffer, 'data' | 'api'>) => any;
  open: boolean;
  rowData: any;
};

const map = {
  currency: 'number',
  number: 'number',
  string: 'text',
  dropdown: 'select',
} as any;

const AddCustomOffer = ({ handleClose, rowData, group, open }: Props) => {
  const [values, setValues] = useState<any>({
    status: 'active',
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});

  const { id } = useParams();

  const { offerGroup, setCustomOffers, setOfferGroup } =
    useContext(OfferGroupContext);
  const client = useCustomOffer();

  const offerClient = useOffer();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'affiliateUrl') {
      setErrorMessage({
        ...errorMessage,
        affiliateUrl: { isError: false, message: '' },
      });
    }
    checkField(name, value, setErrorMessage, errorMessage);
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (preSubmitValidation(errorMessage, setErrorMessage)) {
        setLoading(true);
        const { pathname } = new URL(values?.affiliateUrl);
        const uniqueId = pathname.split('/')[2] || '';

        const payload = {
          groupId: id,
          data: {
            ...values,
          },
          api: {
            clicksExcludeDate: [],
            hideOffer: false,
            clicksExcludeRange: [],
          },
        };

        const offer = await offerClient.checkOfferIfExist().call(uniqueId);

        if (offer.length > 0) {
          if (offerGroup?.trafficSources) {
            const isExist = offerGroup.trafficSources.some((e) => {
              return e.id === offer[0].trafficSourceId;
            });

            if (isExist) {
              const insertOffer = await client
                .insertSingleOffer()
                .call(payload);

              if (insertOffer.status === 200) {
                setCustomOffers([insertOffer.data, ...rowData]);
                showMessage({ message: insertOffer.message });
                handleCancel();
              }
            } else {
              const updatedTS = {
                ...offerGroup,
                trafficSources: [
                  ...offerGroup.trafficSources,
                  {
                    id: offer[0].trafficSourceId,
                    clickId: '',
                  },
                ],
              };
              const updateTrafficSource = await client
                .updateGroupTrafficSource()
                .call(id, updatedTS);
              if (updateTrafficSource.status === 200) {
                const { data } = updateTrafficSource.data;

                setOfferGroup({
                  ...offerGroup,
                  trafficSources: [
                    ...data.trafficSources,
                    { id: offer[0].trafficSourceId, clickid: '' },
                  ],
                });
                const insertOffer = await client
                  .insertSingleOffer()
                  .call(payload);

                if (insertOffer.status === 200) {
                  setCustomOffers([insertOffer.data, ...rowData]);
                  showMessage({ message: insertOffer.message });
                  handleCancel();
                }
              }
            }
          }
        } else {
          showMessage({
            message: 'Affiliate URL is not found',
            severity: 'error',
          });

          handleCancel();
        }
      }
    } catch (error: any) {
      if (error.message === "Failed to construct 'URL': Invalid URL") {
        setErrorMessage({
          ...errorMessage,
          affiliateUrl: {
            isError: true,
            message: 'Affiliate URL  required and should be a valid URL',
          },
        });
      }
    }

    setLoading(false);
  };

  const handleCancel = () => {
    setValues({ status: 'active' });
    setErrorMessage({});
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Add Custom Offer
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        Fields
        <Box display="flex" flexDirection="column" gap={2}>
          {group?.fields?.map((g) => {
            const type = map[g.type] ?? map.string;
            const options = g.dropdownOptions ?? [];

            const isNumber = type === 'number';
            const isSelect = type === 'select';

            if (g.key === 'status') {
              return (
                <TextField
                  select
                  name={g.key}
                  label={g.label}
                  value={values[g.key]}
                  error={errorMessage[g.key]?.isError}
                  helperText={errorMessage[g.key]?.message}
                  onChange={onChange}
                  key={g.key}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </TextField>
              );
            }
            if (g.type === 'boolean') {
              return (
                <TextField
                  select
                  name={g.key}
                  label={g.label}
                  value={values[g.key]}
                  error={errorMessage[g.key]?.isError}
                  helperText={errorMessage[g.key]?.message}
                  onChange={onChange}
                  key={g.key}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </TextField>
              );
            }
            if (isNumber) {
              return (
                <NumericFormat
                  key={g.key}
                  name={g.key}
                  label={g.label}
                  error={errorMessage[g.key]?.isError}
                  helperText={errorMessage[g.key]?.message}
                  customInput={TextField}
                  value={values[g.key]}
                  onChange={onChange}
                />
              );
            }

            if (isSelect) {
              return (
                <TextField
                  select
                  name={g.key}
                  label={g.label}
                  value={values[g.key]}
                  onChange={onChange}
                  key={g.key}
                >
                  {options.map((opt) => {
                    return (
                      <MenuItem key={opt.key} value={opt.key}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }

            return (
              <TextField
                key={g.key}
                type={type}
                name={g.key}
                error={errorMessage[g.key]?.isError}
                helperText={errorMessage[g.key]?.message}
                label={g.label}
                value={values[g.key]}
                onChange={onChange}
              />
            );
          })}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => handleSubmit()}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomOffer;
