import { Search, Close } from '@mui/icons-material';
import { Box, Paper, TextField, IconButton, Tooltip } from '@mui/material';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { IronDomeContext } from '../context';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type Props = {
  onSelectOfferDone: (selectedOffers: any[]) => void;
};

const IronDomeMemberSelector: React.FC<Props> = ({ onSelectOfferDone }) => {
  const [selectedOffers, setSelectedOffers] = useState<any[]>([]);
  const { offers } = useContext(IronDomeContext);
  const gridApiRef = useRef<any>(null);

  useEffect(() => {
    if (gridApiRef.current) {
      const selectedIds = selectedOffers.map((offer) => offer._id);
      gridApiRef.current.api.forEachNode((node: any) => {
        if (selectedIds.includes(node.data._id)) {
          node.setSelected(true);
        }
      });
    }
  }, [offers]);

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      checkboxSelection: true,
      flex: 1,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
  ];

  const onSelectionChanged = (event: any) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    onSelectOfferDone(selectedData);
    setSelectedOffers(selectedData);
  };

  return (
      <div
        className="ag-theme-alpine"
        style={{ height: '800px', width: '250px' }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={offers}
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          onGridReady={(params) => {
            gridApiRef.current = params;
          }}
        />
      </div>
  );
};

export default IronDomeMemberSelector;