import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useContext, useState } from 'react';
import { OfferGroupContext } from '../context';
import { TrafficSource } from '../../traffic/types';
import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { Delete, Edit } from '@mui/icons-material';
import EditGroupTraffic from '../EditGroupTraffic';
import { CustomOfferGroup, OfferGroupTraffic } from '../types';
import useCustomOffer from '../useCustomOffers';
import OfferGroupTrafficSourcesToolbar from '../OfferGroupTrafficSourcesToolbar';
import { showMessage } from '../../../components/utils/showMessage';
import { GridCloseIcon } from '@mui/x-data-grid';
import LoadingButton from '../../../components/LoadingButton';
import DeleteGroupTraffic from '../DeleteGroupTraffic';

type Props = {};

const OfferGroupSources = (props: Props) => {
  const { offerGroup, traffic, setOfferGroup, setLoading, loading } =
    useContext(OfferGroupContext);

  const trafficData = offerGroup?.trafficSources ?? [];

  const [editingRow, setEditingRow] = useState<
    OfferGroupTraffic | null | undefined
  >(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);

  const client = useCustomOffer();

  const map = Object.fromEntries(traffic.map((t) => [t._id, t]));

  const openEditModal = (id: string) => {
    const row = trafficData.find((t) => t.id === id);
    setEditingRow(row);
  };

  const closeEditModal = () => setEditingRow(null);

  const handleDeleteDialogOpen = (id: string) => {
    setDeleteRowId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeleteRowId(null);
  };

  const onAddOfferGroupSourcesDone = async (newValue: OfferGroupTraffic) => {
    console.log('onAddOfferGroupSourcesDone called');
    try {
      const newdata = [...offerGroup!.trafficSources, newValue];

      const newTraffic: CustomOfferGroup = {
        ...offerGroup!,
        trafficSources: newdata,
      };

      await client.updateOfferGroup().call(newTraffic._id, newTraffic);
      showMessage({
        message: 'Traffic source added successfully!',
      });
      setOfferGroup(newTraffic);

      closeEditModal();
    } catch (error: any) {
      showMessage({ message: error.message, severity: 'error' });
    }
  };

  const onEditGroupTraffic = async (newValue: OfferGroupTraffic) => {
    console.log('onEditGroupTraffic called');
    try {
      const newdata = offerGroup!.trafficSources.map((t) => {
        if (t.id === newValue.id) return newValue;
        return t;
      });

      const newTraffic: CustomOfferGroup = {
        ...offerGroup!,
        trafficSources: newdata,
      };

      await client.updateOfferGroup().call(newTraffic._id, newTraffic);
      showMessage({
        message: 'Traffic source updated successfully!',
      });
      setOfferGroup(newTraffic);

      closeEditModal();
    } catch (error: any) {
      showMessage({ message: error.message, severity: 'error' });
    }
  };

  const deleteRow = async (id: string) => {
    if (!deleteRowId) return;
    setLoading(true);
    try {
      const newrows = offerGroup!.trafficSources.filter(
        (t) => t.id !== deleteRowId
      );

      const dto = {
        ...offerGroup!,
        trafficSources: newrows,
      };

      await client.updateOfferGroup().call(dto._id, dto);
      showMessage({ message: 'Traffic source deleted successfully' });
      setOfferGroup(dto);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleDeleteDialogClose();
    }
  };

  const cols = [
    {
      headerName: 'Detected Used Traffic Sources',
      field: 'name' as keyof OfferGroupTraffic,
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      valueGetter: (params: any) => {
        const { id } = params.data;
        return map[id]?.name ?? id;
      },
    },
    {
      headerName: 'Click Id for Report',
      field: 'clickId' as keyof OfferGroupTraffic,
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellRenderer: ({ data }: { data: OfferGroupTraffic }) => {
        const label = data.clickId || 'None';
        return <Chip label={label} />;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions' as keyof OfferGroupTraffic,
      flex: 1,
      cellRenderer: ({ data }: { data: OfferGroupTraffic }) => {
        return (
          <Box display="flex" gap={1}>
            <TooltipIconButton
              title="Edit"
              onClick={() => openEditModal(data.id)}
              icon={<Edit />}
            />
            <TooltipIconButton
              title="Delete"
              onClick={() => handleDeleteDialogOpen(data.id)}
              icon={<Delete />}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <div className="ag-theme-alpine">
        <OfferGroupTrafficSourcesToolbar
          onAddOfferGroupSourcesDone={onAddOfferGroupSourcesDone}
        />
        <AgGridReact
          columnDefs={cols}
          rowData={trafficData}
          getRowId={(params) => params.data.id}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={20}
        />
      </div>
      {editingRow && (
        <EditGroupTraffic
          trafficData={editingRow}
          handleClose={closeEditModal}
          onSubmit={onEditGroupTraffic}
          trafficSource={map[editingRow.id]}
        />
      )}
      {deleteRowId && (
        <DeleteGroupTraffic
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          loading={loading}
          onDelete={() => deleteRow(deleteRowId)}
        />
      )}
    </>
  );
};

export default OfferGroupSources;
