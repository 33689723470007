import React, { useContext, useEffect, useState } from 'react';
import { OfferGroupContext } from '../context';
import { AgGridReact } from 'ag-grid-react';
import { MerchantCommissionSettings } from '../types';
import CommissionDeductionToolbar from './toolbar';
import { nanoid } from 'nanoid';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useCustomOffer from '../useCustomOffers';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { Delete } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import LoadingButton from '../../../components/LoadingButton';
import { showMessage } from '../../../components/utils/showMessage';

type Props = {};

const CommissionDeduction = (props: Props) => {
  const [rows, setRows] = useState<MerchantCommissionSettings[]>([]);
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const { offerGroup, merchants, setOfferGroup } = useContext(OfferGroupContext);
  const client = useCustomOffer();

  const map = Object.fromEntries(
    merchants.map((m) => [m.merchantId, m.merchantName])
  );

  const init = () => {
    if (!offerGroup) return;

    const rows = offerGroup.merchantCommissionSettings ?? [];
    const newRows = rows.map((i) => ({ ...i, _id: i._id ?? nanoid() }));

    setRows(newRows);
  };

  const onDeleteDeduction = async (id: string) => {
    let comSettings = offerGroup!.merchantCommissionSettings.slice() ?? [];

    const updatedComSettings = comSettings.filter(
      (settings) => settings._id !== id
    );

    const newOfferGroup = {
      ...offerGroup!,
      merchantCommissionSettings: updatedComSettings,
    };

    await client.updateOfferGroup().call(offerGroup!._id, {
      merchantCommissionSettings: updatedComSettings,
    });

    setOfferGroup(newOfferGroup);
  };

  const handleDelete = (id: string) => {
    onDeleteDeduction(id);
    setRows((prevRows) => prevRows.filter((row) => row._id !== id));
  };

  const handleOpenDialog = (id: string) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = () => {
    if (deleteId) {
      handleDelete(deleteId);
      showMessage({ message: 'Deduction deleted successfully!' });
    }
    handleCloseDialog();
  };

  const cols: ColDef<MerchantCommissionSettings>[] = [
    {
      headerName: 'M. Id',
      field: 'merchantId' as keyof MerchantCommissionSettings,
      flex: 1,
      checkboxSelection: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      headerCheckboxSelection: true,
    },
    {
      headerName: 'M. Name',
      field: 'merchantName' as keyof MerchantCommissionSettings,
      flex: 1,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      valueGetter: (params: any) => {
        return map[params.data.merchantId] ?? 'No merchant name';
      },
    },
    {
      headerName: 'Deduction rate',
      field: 'deductionRate' as keyof MerchantCommissionSettings,
      flex: 1,
      valueFormatter: (params) => {
        if (params.value == null) return '';

        const decimal = params.value.toFixed(2);

        return `${decimal}%`;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions' as keyof MerchantCommissionSettings,
      flex: 1,
      cellRenderer: (params: any) => {
        return (
          <Box>
            <TooltipIconButton
              title="Delete"
              icon={<Delete />}
              onClick={() => handleOpenDialog(params.data._id)}
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    init();
  }, [offerGroup]);

  useEffect(() => {
    if (selectedRow.length > 0) {
      const currRow = rows.find((row) => row._id === selectedRow[0]);

      const merchantIds = selectedRow.map((item) => {
        const res = rows.find((row) => row._id === item);
        return res?.merchantId;
      });
      const res = {
        deductionRate: currRow?.deductionRate,
        merchantIds: merchantIds,
      };
      setSelectedRowData(res);
    } else {
      setSelectedRowData(null);
    }
  }, [selectedRow]);

  console.log('selectedRow :>> ', selectedRow);

  return (
    <>
      <CommissionDeductionToolbar selectedRow={selectedRowData} />
      <div
        className="ag-theme-alpine"
        style={{ height: '400px', width: '100%' }}
      >
        <AgGridReact
          rowData={rows}
          getRowId={(r) => r.data._id!}
          columnDefs={cols}
          rowSelection="multiple"
          domLayout="autoHeight"
          onSelectionChanged={(event) =>
            setSelectedRow(
              event.api
                .getSelectedRows()
                .map((row) => row._id)
                .filter((id): id is string => id !== undefined)
            )
          }
          pagination={true}
          paginationPageSize={20}
        />
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Delete Custom Offer
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <GridCloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            Are you sure you want to delete the offer setting?
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDelete}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommissionDeduction;
