import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Tooltip, IconButton, Alert, Paper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import {
  Add,
  Edit,
  Delete,
  Dashboard,
  ContentCopy,
  DisabledByDefault,
  Download,
  UploadFile,
} from '@mui/icons-material';
import CampaignDialog from '../CampaignDialog';
import useCampaign from '../useCampaign';
import ConfirmModal from '../../../components/ConfirmModal';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { showMessage } from '../../../components/utils/showMessage';
import TooltipIconButton from '../../../components/TooltipIconButton';
import UploadDialog from '../UploadDialog';

function CampaignTable() {
  const {
    getCampaigns,
    addCampaign,
    editCampaign,
    deleteCampaign,
    bulkAddCampaignOffers,
  } = useCampaign();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<string | null>(null);

  const handleDialogOpen = (campaign: any = null) => {
    setSelectedCampaign(campaign);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedCampaign(null);
  };

  const handleSaveCampaign = async (campaignData: any) => {
    try {
      setLoading(true);
      if (selectedCampaign) {
        await editCampaign().call(selectedCampaign._id, campaignData);
        showMessage({
          message: 'Campaign updated successfully!',
          severity: 'success',
        });
      } else {
        await addCampaign().call(campaignData);
        showMessage({
          message: 'Campaign added successfully!',
          severity: 'success',
        });
      }
      handleDialogClose();
      fetchData();
    } catch (error) {
      showMessage({ message: 'Error saving campaign.', severity: 'error' });
      console.error('Error saving campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCampaign = async (id: string) => {
    try {
      setLoading(true);
      await deleteCampaign().call(id);
      showMessage({
        message: 'Campaign deleted successfully!',
        severity: 'success',
      });
      fetchData();
    } catch (error) {
      showMessage({ message: 'Error deleting campaign.', severity: 'error' });
      console.error('Error deleting campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const campaigns = await getCampaigns().call();
      setData(campaigns);
      setTotalRowCount(campaigns.length);
    } catch (error) {
      showMessage({ message: 'Error fetching campaigns.', severity: 'error' });
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownloadTemplate = async () => {
    const headers = [
      'campaignId',
      'merchantId',
      'merchantName',
      'merchantUrl',
      'affiliateUrl',
      'clickCap',
      'commission',
      'geo',
      'device',
      'status',
    ];
    const csvRows = [];
    csvRows.push(headers.join(','));
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'campaign_offer_template.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleUploadDialogOpen = () => {
    setUploadErrors(null);
    setIsUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadErrors(null);
    setIsUploadDialogOpen(false);
  };

  const handleFileSubmit = (file: File) => {
    setUploadErrors(null);
    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const text = e.target?.result as string;
      const rows = text.split('\n').filter((row) => row.trim() !== '');
      if (rows.length < 2) {
        setUploadErrors('Uploaded file is empty or invalid.');
        return;
      }
      const headers = rows[0].split(',').map((h) => h.trim());
      const offersArray = rows.slice(1).map((row) => {
        const values = row.split(',').map((v) => v.trim());
        const offer: { [key: string]: string } = {};
        headers.forEach((header, index) => {
          offer[header] = values[index];
        });
        return offer;
      });
      try {
        await bulkAddCampaignOffers().call(offersArray);
        showMessage({ message: 'Bulk upload successful', severity: 'success' });
        setUploadErrors(null);
        handleUploadDialogClose();
        fetchData();
      } catch (err: any) {
        setUploadErrors(
          err?.response?.data?.message || err.message || 'Bulk upload failed'
        );
        console.error(err);
      }
    };
    reader.readAsText(file);
  };

  const onPaginationChanged = (event: any) => {
    const currentPage = event.api.paginationGetCurrentPage();
    const pageSize = event.api.paginationGetPageSize();

    if (paginationModel.pageSize !== pageSize) {
      setPaginationModel({ page: currentPage, pageSize });
      fetchData();
    } else if (paginationModel.page !== currentPage) {
      setPaginationModel({ page: currentPage, pageSize });
      fetchData();
    }
  };

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
      cellRenderer: (params: any) => (
        <Link
          to={`/campaigns/${params.data._id}/offers`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {params.value}
        </Link>
      ),
    },
    { headerName: 'Entry Domain', field: 'entryDomain', flex: 1 },
    {
      headerName: 'Offers',
      field: 'offers',
      flex: 1,
      filter: false,
      sortable: false,
      cellRenderer: (params: any) => (
        <Tooltip title="View Offers">
          <Link
            to={`/campaigns/${params.data._id}/offers`}
            style={{ color: 'inherit' }}
          >
            <IconButton>
              <Dashboard />
            </IconButton>
          </Link>
        </Tooltip>
      ),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      filter: false,
      sortable: false,
      cellRenderer: (params: any) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Edit Campaign">
            <IconButton
              size="small"
              onClick={() => handleDialogOpen(params.data)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy Entry Domain">
            <IconButton
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(params.data.entryDomain);
                showMessage({
                  message: 'Entry domain copied to clipboard!',
                  severity: 'success',
                });
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
          <TooltipIconButton
            onClick={() => navigate(`${params.data._id}/block`)}
            title="Block rules"
            icon={<DisabledByDefault />}
          />
          <ConfirmModal
            iconButton
            icon={<Delete />}
            title="Delete Campaign"
            onConfirm={() => handleDeleteCampaign(params.data._id)}
          >
            <Alert severity="warning">
              Are you sure you want to delete this campaign? This action cannot
              be undone.
            </Alert>
          </ConfirmModal>
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Box mt={4} p={1}>
        <Box mb={2} display="flex" justifyContent="space-between">
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Button startIcon={<Download />} onClick={handleDownloadTemplate}>
              Download Template
            </Button>
            <Button startIcon={<UploadFile />} onClick={handleUploadDialogOpen}>
              Upload Offers
            </Button>
          </Box>
          <Box display={'flex'} gap={1}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => handleDialogOpen()}
            >
              Add Campaign
            </Button>
          </Box>
        </Box>
        <Box
          className="ag-theme-alpine"
          style={{ height: '80vh', width: '100%' }}
        >
          <AgGridReact
            rowData={data}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              flex: 1,
            }}
            pagination
            paginationPageSize={paginationModel.pageSize}
            onPaginationChanged={onPaginationChanged}
            onGridReady={(params) => {
              setLoading(false);
            }}
            enableCellTextSelection={true}
            loadingOverlayComponentParams={{ loading }}
          />
        </Box>
        <CampaignDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          onSave={handleSaveCampaign}
          initialCampaign={selectedCampaign}
        />
        <UploadDialog
          open={isUploadDialogOpen}
          onClose={handleUploadDialogClose}
          onSubmit={handleFileSubmit}
          error={uploadErrors || ''}
        />
      </Box>
    </Paper>
  );
}

export default CampaignTable;
