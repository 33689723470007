import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useEffect, useState } from 'react';
import useCustomOffer from '../useCustomOffers';
import { OfferGroupReport } from '../types';
import Delete from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  IconButton,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { GridCloseIcon } from '@mui/x-data-grid';
import LoadingButton from '../../../components/LoadingButton';

type Props = {};

const OfferGroupReportTable = (props: Props) => {
  const [rows, setRows] = useState<OfferGroupReport[]>([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [genReportMessage, setGenReportMessage] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const params = useParams();
  const client = useCustomOffer();

  const init = () => {
    const apiCall = client.getReports();

    (async () => {
      try {
        const res = await apiCall.call(params.id);
        setIsLoading(false);
        setRows(res);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const handleDeleteDialogOpen = (id: string) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const onDeleteReport = async (id: string) => {
    setIsLoading(true);
    try {
      await client.deleteReport().call(id);
      const newRows = rows.filter((r) => r._id !== id);
      setRows(newRows);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onGenerateReport = async () => {
    if (!selectedDate) {
      return;
    }

    try {
      setReportLoading(true);
      const response = await client
        .generateReport()
        .call(params.id, dayjs(selectedDate).format('YYYY-MM-DD'));
      setReportLoading(false);
      setSnackbarOpen(true);
      if (response) {
        setGenReportMessage('Report generated successfully!');
        setRows((prevRows) => [...prevRows, response]);
      } else {
        setGenReportMessage(
          'Report already exists for the selected date. Skipping generation.'
        );
      }
    } catch (error: any) {
      setReportLoading(false);
      if (error.response && error.response.status === 400) {
        setGenReportMessage(
          'Bad Request: Please ensure all required fields are correct.'
        );
      } else {
        setGenReportMessage(
          'Network Error: Failed to generate the report. Please try again later.'
        );
      }
      setSnackbarOpen(true);
      console.error(error);
    } finally {
      setReportLoading(false);
      setDatePickerOpen(false);
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    init();
  }, [params.id]);

  const cols = [
    {
      headerName: 'Date',
      field: 'date' as keyof OfferGroupReport,
      flex: 1,
      sort: 'desc' as 'desc' | 'asc' | undefined,
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => (
        <Link to={`/custom-report/${params.data._id}`}>{params.value}</Link>
      ),
    },
    {
      headerName: 'Actions',
      field: 'actions' as keyof OfferGroupReport,
      cellRenderer: (params: any) => (
        <Box display="flex" gap={1}>
          <TooltipIconButton
            title="Delete"
            onClick={() => handleDeleteDialogOpen(params.data._id)}
            icon={<Delete />}
          />
        </Box>
      ),
    },
  ];

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        message={genReportMessage}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() => setDatePickerOpen(true)}
        style={{ marginBottom: '16px' }}
        disabled={isLoading}
      >
        Generate Report
      </Button>

      <Dialog
        open={datePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        maxWidth="sm"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '500px',
            minHeight: '200px',
            padding: '20px',
          },
        }}
      >
        <DialogTitle>Generate Report</DialogTitle>
        <DialogContent sx={{ height: 'auto' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%', marginTop: '10px' }}
              label="Select Date"
              value={selectedDate}
              maxDate={dayjs()}
              onChange={(newValue) => setSelectedDate(newValue)}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          {reportLoading ? (
            <Button fullWidth disabled>
              <CircularProgress size={14} sx={{ margin: '0px 10px' }} />
              Generating Report...
            </Button>
          ) : (
            <>
              <Button onClick={() => setDatePickerOpen(false)}>Cancel</Button>
              <Button
                onClick={onGenerateReport}
                variant="contained"
                color="primary"
                disabled={!selectedDate || reportLoading}
              >
                Generate
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Delete Report
          <IconButton
            aria-label="close"
            onClick={() => setDeleteDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <GridCloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>Are you sure you want to delete this report?</Typography>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={() => {
              if (deleteId) {
                onDeleteReport(deleteId);
                setDeleteDialogOpen(false);
              }
            }}
            color="primary"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Data Grid */}
      <div
        className="ag-theme-alpine"
        style={{ height: '400px', width: '100%' }}
      >
        <AgGridReact
          columnDefs={cols}
          rowData={rows}
          loadingOverlayComponentParams={{ loading: isLoading }}
          domLayout="autoHeight"
          getRowId={(params) => params.data._id}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
    </div>
  );
};

export default OfferGroupReportTable;
