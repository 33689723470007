const fieldToCheck = [
  'commission',
  'merchantName',
  'merchantId',
  'affiliateUrl',
];

export const checkField = (
  field: string,
  value: string,
  setIsError: any,
  error: any
) => {
  let errorField = {
    isError: false,
    message: '',
  };

  if (fieldToCheck.includes(field)) {
    if (!value || value === '') {
      errorField = {
        isError: true,
        message: `${
          field !== 'affiliateUrl'
            ? `${field} is required`
            : 'Affiliate URL  required and should be a valid URL'
        }`,
      };
    }
  }

  setIsError({ ...error, [field]: errorField });
};

export const preSubmitValidation = (error: any, setError: any) => {
  let onSubmitError: any = {};
  fieldToCheck.map((e) => {
    if (error[e] === undefined || error[e].isError === true) {
      onSubmitError[e] = {
        isError: true,
        message: `${
          e !== 'affiliateUrl'
            ? `${e} is required`
            : 'Affiliate URL  required and should be a valid URL'
        }`,
      };
    } else {
      if (error[e].isError === false) {
        onSubmitError[e] = {
          isError: false,
          message: '',
        };
      }
    }
  });

  setError(onSubmitError);
  return fieldToCheck.every((e) => error[e] && error[e].isError === false);
};
