import React, { useState } from 'react';
import { Range, DateRangePicker } from 'react-date-range';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupIcon from '@mui/icons-material/Group';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import GroupingDialog from '../Grouping';

type Props = {
  onDateFilterSubmit: (ranges: Range[]) => void;
  initialDateRange: Range;
  onGroupByChange: (groupBy: string[]) => void;
  initialGroupBy: string[];
  exportData: any[];
};

const RequestTableToolbarWithGrouping: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
  onGroupByChange,
  initialGroupBy,
  exportData
}) => {
  const [tempDateRange, setTempDateRange] = useState(initialDateRange);
  const [openFilter, setOpenFilter] = useState(false);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);

  const handleDatePickerChange = (ranges: any) => {
    const selectedRange = ranges.selection;
    const start = moment(selectedRange.startDate);
    const end = moment(selectedRange.endDate);
    const totalDays = end.diff(start, 'days') + 1;

    if (totalDays <= 4) {
      setTempDateRange(selectedRange);
    } else {
      console.warn("You can select up to 4 days only.");
    }
  };

  const handleApplyDateRange = () => {
    onDateFilterSubmit([tempDateRange]);
    setOpenFilter(false);
  };

  const handleExportCSV = () => {
    if (!exportData || exportData.length === 0) {
      console.warn("No data available for export.");
      return;
    }

    const reorderedExportData = exportData.map((item) => {
      const reorderedItem: any = { domainLink: item.domainLink };
      initialGroupBy.forEach((field) => {
        reorderedItem[field] = item[field] || '';
      });
      return { ...reorderedItem, ...item };
    });

    const csv = Papa.unparse(reorderedExportData, {
      quotes: true,
      delimiter: ",",
      header: true,
    });

    const nameStartDate = moment(initialDateRange?.startDate).utc().format('YYYY-MM-DD');
    const nameEndDate = moment(initialDateRange?.endDate).utc().format('YYYY-MM-DD');

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `requestsummary-${nameStartDate}-${nameEndDate}.csv`);
  };

  return (
    <Box display="flex" alignItems="center" pb={2} bgcolor="background.paper">
      <Button
        color="primary"
        startIcon={<FilterAltIcon />}
        onClick={() => setOpenFilter(true)}
        sx={{ mr: 1 }}
      >
        Date Filter
      </Button>

      <Button 
        color="primary" 
        startIcon={<GroupIcon />}
        onClick={() => setOpenGroupDialog(true)} 
        sx={{ mr: 1 }}
      >
        Group By
      </Button>

      <Button 
        color="primary" 
        startIcon={<FileDownloadIcon />}
        onClick={handleExportCSV}
      >
        Export CSV
      </Button>
      <Dialog open={openFilter} onClose={() => setOpenFilter(false)}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <DateRangePicker
            ranges={[tempDateRange]}
            onChange={handleDatePickerChange}
            maxDate={new Date()}
            months={1}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFilter(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyDateRange} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      <GroupingDialog
        open={openGroupDialog}
        onClose={() => setOpenGroupDialog(false)}
        onApply={onGroupByChange}
        initialGroupBy={initialGroupBy}
      />
    </Box>
  );
};

export default RequestTableToolbarWithGrouping;