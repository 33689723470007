import useApi from '../../hooks/useApi';

const API_URL = '/api/campaigns';

export default function useCampaign() {
  const { api: apiClient, createApiCall } = useApi();

  const getCampaigns = createApiCall(async ({ signal }) => {
    const {
      data: { campaigns },
    } = await apiClient.get(API_URL, { signal });

    return campaigns;
  });

  const getSingleCampaign = createApiCall(async ({ signal }, id) => {
    const {
      data: { campaign },
    } = await apiClient.get(`${API_URL}/${id}`, { signal });

    return campaign;
  });

  const editCampaign = createApiCall(async ({ signal }, id, newCampaign) => {
    const {
      data: { campaign },
    } = await apiClient.put(`${API_URL}/${id}`, newCampaign, { signal });
    return campaign;
  });

  const deleteCampaign = createApiCall(async ({ signal }, id) => {
    await apiClient.delete(`${API_URL}/${id}`, { signal });
  });

  const addCampaign = createApiCall(async ({ signal }, newCampaign) => {
    const {
      data: { campaign },
    } = await apiClient.post(API_URL, newCampaign, { signal });
    return campaign;
  });

  const bulkAddCampaignOffers = createApiCall(
    async ({ signal }, offersArray) => {
      const {
        data: { offers: newOffers },
      } = await apiClient.post(
        `${API_URL}/bulk-offers`,
        { offers: offersArray },
        { signal }
      );
      return newOffers;
    }
  );

  return {
    addCampaign,
    getCampaigns,
    getSingleCampaign,
    editCampaign,
    deleteCampaign,
    bulkAddCampaignOffers,
  };
}
