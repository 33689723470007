import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  Divider,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function DeleteTraffic({
  open,
  initialData,
  handleClose,
  onSubmit,
}) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Confirm Deletion</DialogTitle>
      <Divider />
      <DialogContent>
        <Alert severity="error">
          Are you sure you want to delete this traffic source?
        </Alert>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => onSubmit(initialData, handleClose)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
