import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SingleOfferGroupToolbar from '../SingleOfferGroupToolbar';
import { CustomOffer } from '../types';
import useCustomOffer from '../useCustomOffers';
import { useContext, useState } from 'react';
import { OfferGroupContext } from '../context';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { Delete, Edit } from '@mui/icons-material';
import { Alert, Box, Chip } from '@mui/material';
import ConfirmModal from '../../../components/ConfirmModal';
import EditCustomOffer from '../EditCustomOffer';
import BulkEditCustomOffer from '../BulkEditCustomOffer';
import { showMessage } from '../../../components/utils/showMessage';
import DeleteCustomOffer from '../DeleteCustomOffer';
import AddCustomOffer from '../AddCustomOffer';

type Props = {};

const SingleOfferGroupTable = (props: Props) => {
  const client = useCustomOffer();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [bulkOpen, setBulkOpen] = useState(false);

  const { offerGroup, customOffers, loading, setLoading, setCustomOffers } =
    useContext(OfferGroupContext);

  const [rowToEdit, setRowToEdit] = useState<CustomOffer | null>(null);
  const [rowToDelete, setRowToDelete] = useState<CustomOffer | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addCustomOfferDialog, setAddCustomOfferDialog] = useState(false);

  const handleDeleteDialogOpen = (row: CustomOffer) => {
    setRowToDelete(row);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  const deleteRow = async (_id: CustomOffer['_id']) => {
    setLoading(true);
    try {
      await client.deleteCustomOffer().call(_id);

      const newRows = customOffers.filter((r) => r._id !== _id);
      showMessage({ message: 'Record deleted successfully!.' });
      setCustomOffers(newRows);
    } catch (error: any) {
      showMessage({
        message: error.message || 'Something went wrong.',
        severity: 'error',
      });
    }
    setLoading(false);
    handleDeleteDialogClose();
  };

  const closeEditModal = () => setRowToEdit(null);

  const closeAddModal = () => setAddCustomOfferDialog(false);

  const onEditCustomOffer = async (
    id: string,
    row: Pick<CustomOffer, 'data' | 'api'>
  ) => {
    try {
      await client.updateCustomOffer().call(id, row);

      const newOffer = customOffers.map((c) => {
        if (c._id === id)
          return {
            ...c,
            api: row.api,
            data: row.data,
          };

        return c;
      });
      showMessage({ message: 'Record updated successfully!' });
      setCustomOffers(newOffer);
    } catch (error: any) {
      showMessage({
        message: error.message || 'Something went wrong.',
        severity: 'error',
      });
    }
  };

  const handleBulkSubmit = async (hideOffer: boolean) => {
    setLoading(true);

    const updatedOffers = customOffers.map((offer) => {
      if (selectedRows.includes(offer._id)) {
        return {
          ...offer,
          api: {
            ...offer.api,
            hideOffer: hideOffer,
          },
          data: {
            ...offer.data,
            status: hideOffer === true ? 'inactive' : 'active',
          },
        };
      }
      return offer;
    });

    setCustomOffers(updatedOffers);

    try {
      await Promise.all(
        selectedRows.map((id) => {
          const offer = customOffers.find((offer) => offer._id === id);
          if (offer) {
            return client.updateCustomOffer().call(id, {
              ...offer,
              api: { ...offer.api, hideOffer },
              data: {
                ...offer.data,
                status: hideOffer === true ? 'inactive' : 'active',
              },
            });
          }
          return Promise.resolve();
        })
      );
      showMessage({ message: 'Offer/s updated successfully!' });
    } catch (error: any) {
      showMessage({
        message: error.message || 'Something went wrong.',
        severity: 'error',
      });
    }

    setLoading(false);
  };

  const getCols = () => {
    if (!offerGroup) return [];

    const fieldcols = offerGroup.fields.map((f) => {
      const col = {
        headerName: f.label,
        field: f.key as keyof CustomOffer,
        flex: 1,
        valueGetter: (params: any) => {
          return params.data ? params.data.data[f.key] : '';
        },
        ...(f.key === 'status' && {
          filter: false,
          cellRenderer: (params: { value: string }) => (
            <Chip
              label={params.value}
              color={params.value === 'inactive' ? 'error' : 'success'}
            />
          ),
        }),
        ...(f.type === 'boolean' && {
          cellRenderer: (params: { value: string }) => (
            <Chip
              label={params.value === 'true' ? 'True' : 'False'}
              color={params.value === 'true' ? 'success' : 'error'}
            />
          ),
        }),
        ...(f.key === 'merchantId' && {
          checkboxSelection: true,
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          headerCheckboxSelection: true,
        }),
        ...(f.key === 'merchantName' && {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
        }),
        ...(f.key === 'merchantUrl' && {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
        }),
        ...(f.key === 'affiliateUrl' && {
          floatingFilter: true,
          filter: 'agTextColumnFilter',
        }),
      };
      return col;
    });

    const actioncol = {
      headerName: 'Actions',
      field: '_id' as keyof CustomOffer,
      cellRenderer: ({ data }: { data: CustomOffer }) => {
        return (
          <Box>
            <TooltipIconButton
              title="Update"
              icon={<Edit />}
              onClick={() => setRowToEdit(data)}
            />
            <TooltipIconButton
              title="Delete"
              icon={<Delete />}
              onClick={() => handleDeleteDialogOpen(data)}
            />
          </Box>
        );
      },
    };

    return [...fieldcols, actioncol];
  };

  const cols = getCols();

  const onImportDone = (results: CustomOffer[]) => {
    setCustomOffers([...customOffers, ...results]);
  };

  const onDownloadTemplate = () => {
    if (!offerGroup) return;

    const fields = offerGroup.fields.map((f) => f.key).join(',');
    const blob = new Blob([fields], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${offerGroup.label}-template.csv`;
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onExport = () => {
    if (!offerGroup) return;

    const csvData = [
      offerGroup.fields.map((f) => f.label).join(','),
      ...customOffers.map((offer) =>
        offerGroup.fields.map((f) => offer.data[f.key] || '').join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${offerGroup.label}-offers.csv`;
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: '500px', width: '100%' }}
      >
        <SingleOfferGroupToolbar
          onDownloadTemplate={onDownloadTemplate}
          onImportDone={onImportDone}
          onExport={onExport}
          selectedRows={selectedRows}
          setAddCustomOfferDialog={setAddCustomOfferDialog}
          onBulkEdit={() => setBulkOpen(true)}
        />
        <AgGridReact
          columnDefs={cols}
          rowData={customOffers}
          rowSelection="multiple"
          onSelectionChanged={(event) => {
            setSelectedRows(event.api.getSelectedRows().map((row) => row._id));
          }}
          enableCellTextSelection={true}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={20}
        />
      </div>
      <AddCustomOffer
        open={addCustomOfferDialog}
        rowData={customOffers}
        handleClose={closeAddModal}
        onSubmit={onEditCustomOffer}
        group={offerGroup!}
      />
      <BulkEditCustomOffer
        open={bulkOpen}
        handleClose={() => setBulkOpen(false)}
        onSubmit={handleBulkSubmit}
      />
      {rowToEdit && (
        <EditCustomOffer
          row={rowToEdit}
          open={true}
          handleClose={closeEditModal}
          onSubmit={onEditCustomOffer}
          group={offerGroup!}
        />
      )}
      {rowToDelete && (
        <DeleteCustomOffer
          row={rowToDelete}
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          onDelete={deleteRow}
        />
      )}
    </>
  );
};

export default SingleOfferGroupTable;
