import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddCustomOfferGroup from '../AddOfferGroup';
import EditCustomOfferGroup from '../EditOfferGroup';
import { CustomOfferGroup } from '../types';
import useCustomOffer from '../useCustomOffers';

type Props = {
  onAddOfferGroupDone: (newGroup: CustomOfferGroup) => any;
  onEditOfferGroupDone: (updatedGroup: CustomOfferGroup) => any;
  selectedRow: any;
};

const OfferGroupToolbar = ({ onAddOfferGroupDone, onEditOfferGroupDone, selectedRow }: Props) => {
  const api = useCustomOffer();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const onAddOfferGroup = async (newGroup: CustomOfferGroup) => {
    const result = await api.createOfferGroup().call(newGroup);
    onAddOfferGroupDone(result);
    setAddDialogOpen(false);
  };

  const onEditOfferGroup = async (updatedGroup: CustomOfferGroup) => {
    const result = await api.updateOfferGroup().call(selectedRow._id, updatedGroup);

    if (result) {
      const mergedGroup = {
        ...selectedRow,
        ...updatedGroup,
        trafficSources: selectedRow.trafficSources,
        _id: selectedRow._id,
        createdAt: selectedRow.createdAt,
        updatedAt: new Date().toISOString(),
        __v: selectedRow.__v,
      };
      onEditOfferGroupDone(mergedGroup);
      setEditDialogOpen(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1} p={1}>
      <Tooltip title="Add">
        <Button
          startIcon={<AddIcon/>}
          onClick={() => setAddDialogOpen(true)}
        >
          Add
        </Button>
      </Tooltip>
      {Boolean(selectedRow) && (
        <Tooltip title="Edit">
          <Button
            startIcon={<EditIcon/>}
            onClick={() => setEditDialogOpen(true)}
          >
            Edit
          </Button>
        </Tooltip>
      )}

      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Custom Offer Group</DialogTitle>
        <DialogContent>
          <AddCustomOfferGroup
            open={addDialogOpen}
            handleClose={() => setAddDialogOpen(false)}
            onSubmit={onAddOfferGroup}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Custom Offer Group</DialogTitle>
        <DialogContent>
          <EditCustomOfferGroup
            open={editDialogOpen}
            handleClose={() => setEditDialogOpen(false)}
            onSubmit={onEditOfferGroup}
            offerData={selectedRow}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OfferGroupToolbar;