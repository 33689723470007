import AddTraffic from '../../AddTraffic';
import EditTraffic from '../../EditTraffic';
import useTraffic from '../../useTraffic';
import DeleteTraffic from '../../DeleteTraffic';
import DynamicToolbar from '../../../../components/DynamicToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import { showMessage } from '../../../../components/utils/showMessage';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArchiveTraffic from '../../ArchiveTraffic';

function TrafficToolbar({
  selectedRow,
  onAddTrafficDone,
  onEditTrafficDone,
  onDeleteTrafficDone,
  rowToDelete,
  rowToArchive,
  currentData,
  onBulkArchiveDone,
  showArchive,
}) {
  const { addTraffic, editTraffic, deleteTraffic, bulkArchiveTraffic } =
    useTraffic();

  const onAddTraffic = async (trafficValues, handleClose) => {
    try {
      const newTraffic = await addTraffic().call(trafficValues);
      onAddTrafficDone(newTraffic);

      handleClose();

      showMessage({
        message: 'Traffic entry added successfully!',
        severity: 'success',
      });
    } catch (error) {
      showMessage({
        message: 'Error adding traffic entry.',
        severity: 'error',
      });
      console.error('Error adding traffic:', error);
    }
  };

  const onEditTraffic = async (trafficValues, handleClose) => {
    try {
      const updatedTraffic = await editTraffic().call(
        selectedRow._id,
        trafficValues
      );

      onEditTrafficDone(updatedTraffic);

      handleClose();

      showMessage({
        message: 'Traffic entry updated successfully!',
        severity: 'success',
      });
    } catch (error) {
      showMessage({
        message: 'Error updating traffic entry.',
        severity: 'error',
      });
      console.error('Error editing traffic:', error);
    }
  };

  const onDeleteTraffic = async (id) => {
    try {
      await deleteTraffic().call(id);
      onDeleteTrafficDone(id);
      showMessage({
        message: 'Traffic entry deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      showMessage({
        message: 'Error deleting traffic entry.',
        severity: 'error',
      });
      console.error('Error deleting traffic:', error);
    }
  };

  const onArchiveTraffic = async (initialData, archive) => {
    try {
      const traffic = await bulkArchiveTraffic().call(initialData, archive);
      onBulkArchiveDone(traffic.data.updatedTraffic);
      showMessage({
        message: archive
          ? 'Traffic entries archived successfully!'
          : 'Traffic entries unarchived successfully!',
        severity: 'success',
      });
    } catch (error) {
      showMessage({
        message: 'Error archiving/unarchiving traffic entries.',
        severity: 'error',
      });
      console.error('Error archiving traffic:', error);
    }
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open, handleClose) => (
        <AddTraffic
          open={open}
          handleClose={handleClose}
          onSubmit={(values) => onAddTraffic(values, handleClose)}
        />
      ),
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRow),
      icon: <EditIcon />,
      render: (open, handleClose) => (
        <EditTraffic
          open={open}
          handleClose={handleClose}
          onSubmit={(values) => onEditTraffic(values, handleClose)}
          initialData={selectedRow}
        />
      ),
    },
    {
      name: 'archive',
      label: 'Bulk Archive/Unarchive',
      renderIf: rowToArchive.length > 1,
      icon: <ArchiveIcon />,
      render: (open, handleClose) => (
        <ArchiveTraffic
          open={open}
          handleClose={handleClose}
          onSubmit={onArchiveTraffic}
          initialData={rowToArchive}
          showArchive={showArchive}
        />
      ),
    },
    {
      name: 'delete',
      label: 'Delete',
      renderIf: Boolean(rowToDelete.length > 0),
      icon: <DeleteIcon />,
      render: (open, handleClose) => (
        <DeleteTraffic
          open={open}
          handleClose={handleClose}
          onSubmit={onDeleteTraffic}
          initialData={rowToDelete}
        />
      ),
    },
  ];

  return <DynamicToolbar modalButtons={modalButtons} />;
}

export default TrafficToolbar;
