import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import LoadingButton from '../../../components/LoadingButton';

type Props = {
  open: boolean;
  handleClose: () => void;
  loading: boolean;
  onDelete: () => void;
};

const DeleteGroupTraffic = ({ open, handleClose, loading, onDelete }: Props) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Delete Traffic Source
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>Are you sure you want to delete this row?</Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onDelete}
          color="primary"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGroupTraffic;