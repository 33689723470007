import { Chip, Box } from '@mui/material';
import { GridColDef, DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { Delete } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import { BlockRule } from '../../pages/traffic/types';
import ConfirmModal from '../ConfirmModal';

type Props = {
  blockRules: BlockRule[];
  tableLoading?: boolean;
  onDelete: (newRules: BlockRule[]) => any;
  onSelectRule: (selectedRule: BlockRule) => any;
};

const RuleTable = ({
  blockRules,
  tableLoading = false,
  onDelete,
  onSelectRule,
}: Props) => {
  const [rules, setRules] = useState<BlockRule[]>([]);

  const renderChipCell = (row: any, field: keyof BlockRule) => {
    const colors = ['default', 'error'] as const;
    const labels = ['Allow', 'Blocked'];

    const value = row[field]; 
    const color = colors[+value];
    const label = labels[+value];

    return <Chip label={label} color={color}></Chip>;
  };

  const init = () => {
    const br = [blockRules].flat();

    const a = br.map((item: any) => {
      return {
        ...item,
        type: item.type ?? 'all',
        id: item.id ?? nanoid(),
      };
    });

    setRules(a);
  };

  const handleDelete = (rowId: string) => {
    const newRules = rules.filter((item) => item.id !== rowId);
    onDelete(newRules);
  };

  useEffect(() => {
    init();
  }, [blockRules]);

  const cols: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Type',
      field: 'type',
      flex: 1,
    },
    {
      headerName: 'Priority',
      field: 'priority',
      flex: 1,
      valueGetter: ({ row }) => {
        return row.priority ?? 1;
      },
    },
    {
      headerName: 'Block Anon',
      field: 'blockAnon',
      renderCell: ({ row }) => renderChipCell(row, 'blockAnon'),
      flex: 1,
    },
    {
      headerName: 'Block VPN/Data center',
      field: 'blockDataCenter',
      renderCell: ({ row }) => renderChipCell(row, 'blockDataCenter'),
      flex: 1,
    },
    {
      headerName: 'Block Bad IP',
      field: 'blockBadIps',
      renderCell: ({ row }) => {
        const map = {
          gen: 'General',
          ddos: 'DDOS',
          recon: 'Crawlers',
        };

        const chips = row.blockBadIps.map((val: keyof typeof map) => {
          const label = map[val];
          return <Chip label={label} />;
        });

        return <Box>{chips}</Box>;
      },
      flex: 2,
    },
    {
      headerName: 'Block Exts.',
      field: 'blockByDetector',
      renderCell: ({ row }) => renderChipCell(row, 'blockByDetector'), 
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <ConfirmModal
              onConfirm={() => {
                handleDelete(row.id);
              }}
              title="Delete"
              icon={<Delete />}
            >
              Do you want to delete this rule?
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  const onSelectRow = (id: GridRowSelectionModel) => {
    const res = rules.find((item: any) => item.id === id[0]);
    onSelectRule(res!);
  };

  return (
    <div>
      <DataGrid
        rows={rules}
        columns={cols}
        autoHeight
        getRowId={(r) => r.id}
        onRowSelectionModelChange={onSelectRow}
        loading={tableLoading}
      />
    </div>
  );
};

export default RuleTable;