import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '../../../components/LoadingButton';
import useTraffic from '../../traffic/useTraffic';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (newTraffic: any) => any;
};

const AddCustomOfferGroupTrafficSource = ({
  open,
  handleClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [traffic, setTraffic] = useState([]);
  const [selectedTraffic, setSelectedTraffic] = useState<any>(null);
  const [error, setError] = useState('');
  const { getTraffic } = useTraffic();

  const init = () => {
    setLoading(true);
    const apiCall = getTraffic();

    (async function () {
      try {
        const traffic = await apiCall.call();
        setTraffic(traffic);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const handleSubmit = async () => {
    if (!selectedTraffic) {
      setError('Traffic source is required.');
      return;
    }

    setLoading(true);
    try {
      await onSubmit({ id: selectedTraffic._id, clickId: '' });
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setSelectedTraffic(null);
    setError('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add Traffic Source Data</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Autocomplete
          options={traffic}
          getOptionLabel={(option: any) => option.name}
          onChange={(event, newValue) => {
            setSelectedTraffic(newValue);
            if (newValue) {
              setError('');
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Traffic"
              margin="normal"
              size="small"
              error={!!error}
              helperText={error}
            />
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomOfferGroupTrafficSource;