import React, { useContext, useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from '@mui/material';
import { MerchantData, OfferGroupContext } from '../context';
import LoadingButton from '../../../components/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';

type Props = {
  open: boolean;
  handleClose: any;
  onSubmit: (data: Deduction) => any;
  initialValue?: Deduction;
  form?: string;
};

type Deduction = {
  merchantIds: any[];
  deductionRate: any;
};

const DeductionForm = ({
  open,
  handleClose,
  onSubmit,
  initialValue,
  form,
}: Props) => {
  const [data, setData] = useState<Deduction>(
    initialValue || {
      merchantIds: [],
      deductionRate: 0,
    }
  );
  const { merchants, init } = useContext(OfferGroupContext);

  const [selectedMerchants, setSelectedMerchants] = useState<MerchantData[]>([]);

  useEffect(() => {
    if (open) {
      init();
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (initialValue) {
      const initialSelectedMerchants = merchants.filter((m) =>
        initialValue.merchantIds.includes(m.merchantId)
      );
      setSelectedMerchants(initialSelectedMerchants);
    }
  }, [initialValue, merchants]);

  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setData({
      merchantIds: [],
      deductionRate: 0,
    });
    setSelectedMerchants([]);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onChangeMerchants = (selected: MerchantData[]) => {
    setSelectedMerchants(selected);
  };

  const handleSubmit = async () => {
    if (selectedMerchants.length === 0) return;

    setLoading(true);
    const deductionRate = Math.min(Math.max(+data.deductionRate, 0), 100);

    try {
      await onSubmit({
        merchantIds: selectedMerchants.map((m) => m.merchantId), // Collect IDs of all selected merchants
        deductionRate,
      });
      resetForm();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    handleClose();
  };

  const handleCancel = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      {/* Add Deduction Dialog */}
      {form !== 'bulkEdit' && (
        <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
          <DialogTitle>Add Deduction</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <Autocomplete
                multiple
                disablePortal={false}
                options={merchants}
                getOptionLabel={(m) => `[${m.merchantId}] - ${m.merchantName}`}
                getOptionKey={(m) => m._id}
                onChange={(e, selected) =>
                  onChangeMerchants(selected as MerchantData[])
                }
                value={selectedMerchants}
                renderInput={(params) => (
                  <TextField {...params} label="Merchants" fullWidth />
                )}
              />
            </Box>
            <Box mt={2}>
              <TextField
                type="number"
                onWheel={(e: any) => e.target.blur()}
                onChange={onChange}
                name="deductionRate"
                label="Deduction Rate"
                value={data.deductionRate}
                inputProps={{
                  max: 100,
                  min: 0,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="secondary" variant="contained">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSubmit}
              disabled={loading || selectedMerchants.length === 0}
              color="primary"
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {/* Bulk Edit Deduction Dialog */}
      {form === 'bulkEdit' && (
        <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
          <DialogTitle>Bulk Edit Deduction</DialogTitle>
          <DialogContent>
            <Box mt={2}>
              <TextField
                type="number"
                onWheel={(e: any) => e.target.blur()}
                onChange={onChange}
                name="deductionRate"
                label="Deduction Rate"
                value={data.deductionRate}
                inputProps={{
                  max: 100,
                  min: 0,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="secondary" variant="contained">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
              color="primary"
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeductionForm;