import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Offer {
  merchantId: string;
  merchantName: string;
  merchantUrl: string;
  affiliateUrl: string;
  clickCap: number;
  commission: string;
  geo: string;
  device: string;
  status: 'active' | 'inactive';
}

interface CampaignOfferFormProps {
  open: boolean;
  onClose: () => void;
  onSave: (offer: Offer) => void;
  initialOffer?: Offer | null;
}

const initialOfferState: Offer = {
  merchantId: '',
  merchantName: '',
  merchantUrl: '',
  affiliateUrl: '',
  clickCap: 0,
  commission: '',
  geo: '',
  device: '',
  status: 'active'
};

const CampaignOfferForm: React.FC<CampaignOfferFormProps> = ({
  open,
  onClose,
  onSave,
  initialOffer = null,
}) => {
  const [offer, setOffer] = useState<Offer>(initialOfferState);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (initialOffer && open) {
      setOffer(initialOffer);
      setIsEditing(true);
    } else if (!initialOffer && open) {
      setIsEditing(false);
    }
  }, [initialOffer, open]);

  const handleFieldChange = (field: keyof Offer, value: any) => {
    setOffer({ ...offer, [field]: value });
  };

  const handleSave = () => {
    onSave(offer);
    if (!isEditing) {
      setOffer(initialOfferState);
    }
    onClose();
  };

  const handleCancel = () => {
    setOffer(initialOfferState);
    onClose();
  };

  const handleClose = () => {
    if (isEditing) {
      setOffer(initialOfferState);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {initialOffer ? 'Edit Offer' : 'Add New Offer'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Offer Details</Typography>
          <TextField
            size="small"
            fullWidth
            label="Merchant ID"
            value={offer.merchantId}
            onChange={(e) => handleFieldChange('merchantId', e.target.value)}
          />
          <TextField
            size="small"
            fullWidth
            label="Merchant Name"
            value={offer.merchantName}
            onChange={(e) => handleFieldChange('merchantName', e.target.value)}
          />
          <TextField
            size="small"
            fullWidth
            label="Merchant URL"
            value={offer.merchantUrl}
            onChange={(e) => handleFieldChange('merchantUrl', e.target.value)}
          />
          <TextField
            size="small"
            fullWidth
            label="Offer Link"
            value={offer.affiliateUrl}
            onChange={(e) => handleFieldChange('affiliateUrl', e.target.value)}
          />
          <NumericFormat
            value={offer.clickCap}
            customInput={TextField}
            size="small"
            fullWidth
            label="Click Cap"
            thousandSeparator
            allowNegative={false}
            onValueChange={(values) =>
              handleFieldChange('clickCap', values.floatValue || 0)
            }
          />
          <TextField
            size="small"
            fullWidth
            label="Commission"
            value={offer.commission}
            onChange={(e) => handleFieldChange('commission', e.target.value)}
          />
          <TextField
            size="small"
            fullWidth
            label="Geo"
            value={offer.geo}
            onChange={(e) => handleFieldChange('geo', e.target.value)}
          />
          <TextField
            size="small"
            fullWidth
            label="Device"
            value={offer.device}
            onChange={(e) => handleFieldChange('device', e.target.value)}
          />
          <TextField
            select
            size="small"
            fullWidth
            label="Status"
            value={offer.status}
            onChange={(e) => handleFieldChange('status', e.target.value)}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignOfferForm;
