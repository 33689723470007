import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useEffect, useState, useMemo, useRef } from 'react';
import { CustomOfferGroup } from '../types';
import { Link } from 'react-router-dom';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import OfferGroupToolbar from '../OfferGroupToolbar';
import useCustomOffer from '../useCustomOffers';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { showMessage } from '../../../components/utils/showMessage';

const OfferGroupTable = () => {
  const client = useCustomOffer();
  const [groups, setGroups] = useState<CustomOfferGroup[]>([]);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<'apiKey' | 'delete' | null>(
    null
  );
  const [dialogData, setDialogData] = useState<CustomOfferGroup | null>(null);
  const gridApiRef = useRef<any>(null);

  const init = () => {
    setIsLoading(true);
    const apiCall = client.getOfferGroups();

    (async () => {
      try {
        const result = await apiCall.call();
        setGroups(result);
        setIsLoading(false);
      } catch (error) {}
    })();

    return apiCall.abort;
  };

  const onEditOfferGroupDone = (updatedGroup: CustomOfferGroup) => {
    const newGroups = groups.map((item) => {
      if (updatedGroup._id === item._id) return updatedGroup;
      return item;
    });
    setGroups(newGroups);
  };

  // const selectedRowData = useMemo(() => {
  //   return groups.find((item) => item._id === selectedRow);
  // }, [selectedRow, groups]);

  const selectedRowData = groups.find((item) => item._id === selectedRow);

  const deleteGroup = async (_id: CustomOfferGroup['_id']) => {
    await client.deleteOfferGroup().call(_id);
    showMessage({ message: 'Offer group deleted successfully!' });
    const newRows = groups.filter((r) => r._id !== _id);
    setGroups(newRows);
    if (selectedRow === _id) {
      setSelectedRow(null);
    }
    setDialogOpen(false);
  };

  const onAddOfferGroupDone = async (newGroup: CustomOfferGroup) => {
    try {
      const result = await client.getOfferGroups().call();
      setGroups(result);
    } catch (error) {
      console.error('Failed to refresh offer groups:', error);
      setGroups((prevGroups) => [...prevGroups, newGroup]);
    }
  };

  useEffect(() => {
    return init();
  }, []);

  const handleOpenDialog = (
    type: 'apiKey' | 'delete',
    data: CustomOfferGroup
  ) => {
    setDialogType(type);
    setDialogData(data);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
    setDialogData(null);
  };

  const columns: ColDef<CustomOfferGroup>[] = [
    {
      headerName: 'Key',
      field: 'key' as keyof CustomOfferGroup,
      flex: 1,
      checkboxSelection: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellRenderer: (params: any) => {
        return (
          <Link to={params.data._id} style={{ cursor: 'pointer' }}>
            {params.data.key}
          </Link>
        );
      },
    },
    {
      headerName: 'Label',
      field: 'label' as keyof CustomOfferGroup,
      flex: 1,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    },
    {
      headerName: 'Actions',
      field: '_id' as keyof CustomOfferGroup,
      cellRenderer: (params: any) => {
        return (
          <Box gap={1}>
            <TooltipIconButton
              icon={<Visibility />}
              title="Show API Key"
              onClick={() => handleOpenDialog('apiKey', params.data)}
              size="small"
            />
            <TooltipIconButton
              icon={<Delete />}
              title="Delete"
              onClick={() => handleOpenDialog('delete', params.data)}
              size="small"
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <div
        className="ag-theme-alpine"
        style={{ height: '1000px', width: '100%' }}
      >
        <OfferGroupToolbar
          onAddOfferGroupDone={onAddOfferGroupDone}
          onEditOfferGroupDone={onEditOfferGroupDone}
          selectedRow={selectedRowData}
        />
        <AgGridReact
          columnDefs={columns}
          rowData={groups}
          getRowId={(params) => params.data._id}
          rowSelection="single"
          domLayout="autoHeight"
          onGridReady={(params) => {
            gridApiRef.current = params.api;
          }}
          onSelectionChanged={(e) => {
            const selectedRows = e.api.getSelectedRows();
            setSelectedRow(selectedRows.length ? selectedRows[0]._id : null);
          }}
        />
      </div>

      {/* Dialog */}
      {dialogOpen && (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {dialogType === 'apiKey' ? 'API Key' : 'Delete Confirmation'}
          </DialogTitle>
          <Divider></Divider>
          <DialogContent>
            {dialogType === 'apiKey' ? (
              <Box>
                {dialogData?.apiKey
                  ? dialogData.apiKey
                  : 'No API key available for this offer.'}
              </Box>
            ) : (
              <Alert severity="error">
                Are you sure you want to delete this offer group?
              </Alert>
            )}
          </DialogContent>
          <Divider></Divider>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
            {dialogType === 'delete' && (
              <Button
                onClick={() => deleteGroup(dialogData!._id)}
                color="error"
                autoFocus
              >
                Delete
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default OfferGroupTable;
