import { Box, Button } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImportOffer from '../pages/offer/ImportOffer';
import { Download } from '@mui/icons-material';

export default function DynamicToolbar({
  modalButtons = [],
  allowExport = false,
  allowExportAll = false,
  allowImport = false,
  exportOptions = {},
  rawExportData = [],
  onImportOffer,
  exportFileName = 'tableData.csv',
  onDownloadTemplate,
  onExportAll
}) {
  const [modalStates, setModalStates] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [allExportData, setAllExportData] = useState([]);


  const exportAllRef = useRef(null);

  useEffect(() => {
    setModalStates(init(modalButtons));
  }, [modalButtons]);

  const handleModalVisibility = (name, isVisible) => {
    const matchModal = modalStates.find((item) => item.name === name);

    matchModal.open = isVisible;

    setModalStates(modalStates.slice());
  };

  const handleOpenModal = (name) => () => {
    handleModalVisibility(name, true);
  };

  const handleCloseModal = (name) => () => handleModalVisibility(name, false);

  const formatExportData = (headerMap, data) => {
    const headerEntries = Object.entries(headerMap);

    const headerLabels = headerEntries.map(([key, options]) => {
      if (typeof options === 'string') return options;
      return options.label;
    });

    const formattedData = data.map((item) => {
      const row = headerEntries.map(([key, options]) => {
        let itemValue = item[key];

        if (key === 'archive' && (itemValue === undefined || itemValue === '')) {
          itemValue = false;
        }
  
        if (options.formatter) {
          return options.formatter(itemValue, item);
        }
        return itemValue;
      });
      return row;
    });

    formattedData.unshift(headerLabels);
    return formattedData;
  };

  const handleExport = (e, done) => {
    const formattedData = formatExportData(exportOptions, rawExportData);
    setExportData(formattedData);
    done(true);
  };

  const handleExportAllButton = async () => {
    try {
      const allOffers = await onExportAll(); 
      const offersArray = allOffers.offers || allOffers; 

      const formatted = formatExportData(exportOptions, offersArray);
      setAllExportData(formatted);
      setTimeout(() => {
        if (exportAllRef.current) {
          exportAllRef.current.link.click();
        }
      }, 0);
    } catch (err) {
      console.error('Error fetching/exporting all data:', err);
    }
  };

  return (
    <>
      <Box p={1} display="flex" gap={1}>
        {modalStates.map((item) => {
          const toRender = Boolean(item.renderIf);

          if (!toRender) return null;

          return (
            <Button onClick={handleOpenModal(item.name)} startIcon={item.icon}>
              {item.label}
            </Button>
          );
        })}

        {allowImport && (
          <Button startIcon={<Download />} onClick={onDownloadTemplate}>
            Download Template
          </Button>
        )}

        {allowExport && (
          <>
            <CSVLink
              data={exportData}
              filename={exportFileName}
              asyncOnClick
              onClick={handleExport}
            >
              <Button startIcon={<FileDownloadIcon />}>Export</Button>
            </CSVLink>
          </>
        )}
        {allowExportAll && (
          <Button onClick={handleExportAllButton} startIcon={<FileDownloadIcon />}>
            Export All
          </Button>
        )}

        {allowImport && (
          <>
            <ImportOffer onUpload={onImportOffer} />
          </>
        )}
      </Box>

      <CSVLink
        data={allExportData}
        filename={exportFileName}
        style={{ display: 'none' }}
        ref={exportAllRef}
      />

      {modalStates.map((item) => {
        const handleClose = handleCloseModal(item.name);

        const toRender = Boolean(item.renderIf);

        if (!toRender) return null;

        return item.render(item.open, handleClose);
      })}
    </>
  );
}

const init = (requestedButtons) => {
  return requestedButtons.map((item) => {
    return {
      ...item,
      open: false,
    };
  });
};
