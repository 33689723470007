import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  onApply: (groupBy: string[]) => void;
  initialGroupBy: string[];
};

const GroupingDialog: React.FC<Props> = ({ open, onClose, onApply, initialGroupBy }) => {
  const [groupBy, setGroupBy] = useState<string[]>(initialGroupBy);

  // Reset groupBy state when the dialog opens.
  useEffect(() => {
    if (open) {
      setGroupBy(initialGroupBy);
    }
  }, [open, initialGroupBy]);

  const handleGroupByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setGroupBy((prev) =>
      checked ? [...prev, name] : prev.filter((item) => item !== name)
    );
  };

  const handleApply = () => {
    onApply(groupBy);
    onClose();
  };

  const handleCancel = () => {
    // Reset local state to initial values and close
    setGroupBy(initialGroupBy);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Group By</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={groupBy.includes('trafficSource')}
              onChange={handleGroupByChange}
              name="trafficSource"
            />
          }
          label="Traffic Source"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={groupBy.includes('offer')}
              onChange={handleGroupByChange}
              name="offer"
            />
          }
          label="Offer"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={groupBy.includes('date')}
              onChange={handleGroupByChange}
              name="date"
            />
          }
          label="Date"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleApply} color="primary" variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupingDialog;