import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OfferContext } from '../../offer/context';

interface CampaignData {
  name: string;
  entryDomain: string;
  entryDomainId: string;
}

interface CampaignDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: CampaignData) => void;
  initialCampaign?: CampaignData | null;
}

const CampaignDialog: React.FC<CampaignDialogProps> = ({
  open,
  onClose,
  onSave,
  initialCampaign,
}) => {
  const initialState: CampaignData = {
    name: '',
    entryDomain: '',
    entryDomainId: '',
  };
  const [campaignData, setCampaignData] = useState<CampaignData>(initialState);
  const [errors, setErrors] = useState<{ name?: string; entryDomain?: string }>(
    {}
  );
  const [isEditing, setIsEditing] = useState(false);

  const { domains } = useContext(OfferContext) as { domains: any[] };
  const entryDomains =
    domains?.filter((item: any) => item.active && item.isEntry) || [];

  useEffect(() => {
    if (initialCampaign && open) {
      setCampaignData(initialCampaign);
      setIsEditing(true);
    } else if (!initialCampaign && open) {
      setIsEditing(false);
    }
  }, [initialCampaign, open]);

  const validate = () => {
    const newErrors: { name?: string; entryDomain?: string } = {};
    if (!campaignData.name.trim()) {
      newErrors.name = 'Campaign name is required';
    }
    if (!campaignData.entryDomainId.trim()) {
      newErrors.entryDomain = 'Entry domain is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      onSave(campaignData);
      setCampaignData(initialState);
      onClose();
    }
  };

  const handleClose = () => {
    if (isEditing) {
      setCampaignData(initialState);
    }
    setErrors({});
    onClose();
  };

  const handleCancel = () => {
    setCampaignData(initialState);
    setErrors({});
    onClose();
  };

  const handleChange = (field: keyof CampaignData, value: string) => {
    setCampaignData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {initialCampaign ? 'Edit Campaign' : 'Add Campaign'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Campaign Name"
                value={campaignData.name}
                onChange={(e) => handleChange('name', e.target.value)}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={entryDomains}
                getOptionLabel={(option: any) => option.domainName}
                isOptionEqualToValue={(option: any, value: any) =>
                  option._id === value._id
                }
                value={
                  entryDomains.find(
                    (domain: any) => domain._id === campaignData.entryDomainId
                  ) || null
                }
                onChange={(event, newValue: any | null) => {
                  console.log('Selected domain:', newValue);
                  setCampaignData((prev) => ({
                    ...prev,
                    entryDomain: newValue ? newValue.domainLink : '',
                    entryDomainId: newValue ? newValue._id : '',
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Entry Domain"
                    variant="outlined"
                    error={!!errors.entryDomain}
                    helperText={errors.entryDomain}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignDialog;
