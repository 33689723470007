import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { CustomOffer } from '../types';
import LoadingButton from '../../../components/LoadingButton';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  handleClose: () => any;
  row: CustomOffer;
  onDelete: (id: string) => any;
  open: boolean;
};

const DeleteCustomOffer = ({ handleClose, row, onDelete, open }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      await onDelete(row._id);
      setLoading(false);
      handleClose();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Delete Custom Offer
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to delete the offer with merchant ID: {row.data.merchantId}?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleDelete}
          color="primary"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomOffer;