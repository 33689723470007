import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridApi, ColumnState } from 'ag-grid-community';
import {
  Box,
  CircularProgress,
  Paper,
  Chip,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import useClicks from '../../useClicks';
import moment from 'moment';
import RequestTableToolbar from '../Toolbar';
import { Range } from 'react-date-range';
import { showMessage } from '../../../../components/utils/showMessage';

function RequestLogs() {
  const [data, setData] = useState([]);
  const [dataForExport, setDataForExport] = useState([]);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sortModel, setSortModel] = useState<{
    sortColumn: string | null;
    sort: 'asc' | 'desc' | null;
  }>({
    sortColumn: "createdAt",
    sort: "desc",
  });
  const client = useClicks();
  const gridApi = useRef<GridApi | null>(null);

  const fetchData = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const res = await client
        .getClicks()
        .call(
          moment(dateRange.startDate).format('YYYY-MM-DD'),
          moment(dateRange.endDate).format('YYYY-MM-DD'),
          page,
          pageSize,
          sortModel.sortColumn,
          sortModel.sort
        );

      setData(res.clicks.result);
      setTotalRowCount(res.clicks.totalCount);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination.page, pagination.pageSize);
  }, [dateRange, pagination.page, pagination.pageSize, sortModel]);

  const fetchDataForExport = async () => {
    let pageExport = 0;
    let pageSizeExport = 1000000;
    try {
      showMessage({
        message: 'Fetching data for export',
      });

      const res = await client
        .getClicks()
        .call(
          moment(dateRange.startDate).format('YYYY-MM-DD'),
          moment(dateRange.endDate).format('YYYY-MM-DD'),
          pageExport,
          pageSizeExport
        );

      return res.clicks.result;
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  };

  const handleDateFilterSubmit = (ranges: Range[]) => {
    const newDateRange = ranges[0];
    setDateRange(newDateRange);
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
  };

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: event.target.value as number,
      page: 0,
    }));
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const onSortChanged = () => {
    const sortState = gridApi
      .current!.getColumnState()
      .filter((col) => col.sort !== null && col.sort !== undefined)
      .map((col) => ({
        colId: col.colId,
        sort: col.sort,
        sortIndex: col.sortIndex,
      }));

    if (sortState.length > 0) {
      setSortModel({
        sortColumn: sortState[0].colId,
        sort: sortState[0].sort ?? null,
      });
    } else {
      setSortModel({ sortColumn: "createdAt", sort: "desc" });
    }
  };

  const getBlockingRuleChips = (blockingRules: any[]) => {
    const chips = blockingRules
      ?.map((rule, index) => {
        const blockReasons = Object.keys(rule.rule || {}).filter(
          (key) => rule.rule[key]
        );
        return blockReasons.map((reason, reasonIndex) => (
          <Chip
            key={`${index}-${reasonIndex}`}
            label={reason}
            color="error"
            sx={{
              height: '24px',
              fontSize: '0.75rem',
              lineHeight: '20px',
              margin: 'auto',
            }}
          />
        ));
      })
      .flat();

    if (chips.length === 0) {
      return (
        <Chip
          label="Not Blocked"
          color="success"
          sx={{
            height: '24px',
            fontSize: '0.75rem',
            lineHeight: '20px',
            margin: 'auto',
          }}
        />
      );
    }

    return chips;
  };

  const columns: ColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      flex: 1,
      valueFormatter: (params: any) => {
        return (
          moment.utc(params.data.createdAt).format('YYYY-MM-DD HH:mm:ss') +
          ' UTC'
        );
      },
    },
    { field: 'request.ip', headerName: 'IP', flex: 1 },
    { field: 'offerDetails.name', headerName: 'Offer', flex: 1 },
    { field: 'trafficDetails.name', headerName: 'Traffic Source', flex: 1 },
    { field: 'domainDetails.domainLink', headerName: 'Domain', flex: 1 },
    {
      field: 'blockingRules',
      headerName: 'Blocked Reason',
      flex: 1,
      cellStyle: { display: 'flex', alignItems: 'center', padding: '0' },
      valueGetter: (params: any) => {
        const blockingReasons =
          params.data.blockingRules
            ?.map((rule: any) => {
              return Object.keys(rule.rule || {}).filter(
                (key) => rule.rule[key]
              );
            })
            .flat() || [];
        return blockingReasons.join(', ');
      },
      cellRenderer: (params: any) => (
        <Box>{getBlockingRuleChips(params.data.blockingRules)}</Box>
      ),
    },
  ];

  const getRowHeight = (params: any) => {
    const blockingRules = params.data.blockingRules || [];
    const blockReasonsCount = blockingRules.reduce(
      (count: number, rule: any) => {
        return (
          count +
          Object.keys(rule.rule || {}).filter((key) => rule.rule[key]).length
        );
      },
      0
    );
    return blockReasonsCount > 1 ? 50 + (blockReasonsCount - 1) * 24 : 50;
  };

  return (
    <Box className="ag-theme-alpine" style={{ height: '700px', width: '100%' }}>
      <Box display={'flex'} gap={1} alignItems={'center'} mb={2}>
        <Chip
          variant="outlined"
          color="primary"
          label={`Starts From: ${moment(dateRange.startDate).format(
            'YYYY-MM-DD'
          )}`}
        ></Chip>
        <Chip
          variant="outlined"
          color="primary"
          label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
        ></Chip>
      </Box>
      <RequestTableToolbar
        onDateFilterSubmit={handleDateFilterSubmit}
        initialDateRange={dateRange}
        onExportData={fetchDataForExport}
      />
      <Box position="relative" height="100%">
        <AgGridReact
          rowData={data}
          columnDefs={columns}
          defaultColDef={{
            sortable: true,
            filter: true,
            floatingFilter: true,
            flex: 1,
          }}
          enableCellTextSelection={true}
          pagination={false}
          rowHeight={50}
          getRowHeight={getRowHeight}
          onSortChanged={onSortChanged}
          onGridReady={(params) => {
            gridApi.current = params.api;
          }}
        />
        {loading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={1}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center" ml={2}>
          <Box component="span" sx={{ mr: 1 }}>
            Page {pagination.page + 1} of{' '}
            {Math.ceil(totalRowCount / pagination.pageSize)} | Total clicks:{' '}
            {totalRowCount}
          </Box>
          <Select
            value={pagination.pageSize}
            onChange={handlePageSizeChange}
            size="small"
            sx={{ mr: 2 }}
          >
            {[10, 25, 50, 100].map((size) => (
              <MenuItem key={size} value={size}>
                {size} per page
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePageChange(Math.max(0, pagination.page - 1))}
          disabled={pagination.page === 0}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePageChange(pagination.page + 1)}
          disabled={
            (pagination.page + 1) * pagination.pageSize >= totalRowCount
          }
          sx={{ ml: 2 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default RequestLogs;
