import React, { memo, useContext, useMemo } from 'react';
import DynamicToolbar from '../../../../components/DynamicToolbar';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';

import AddOffer from '../../AddOffer';
import EditOffer from '../../EditOffer';
import DeleteOffer from '../../DeleteOffer';
import TestOffer from '../../TestOffer';
import useOffer from '../../useOffer';

import { OfferContext } from '../../context';
import ArchiveOffer from '../../ArchiveOffer';
import BulkEditOffer from '../../EditOffer/bulkEdit';

function OfferToolbar({
  selectedRow,
  multipleDataSelected,
  filteredOffers,
  rowToArchive,
  onBulkArchiveDone,
  onAddOfferDone,
  onEditOfferDone,
  onEditBulkOfferDone,
  onDeleteOfferDone,
  onImportOfferDone,
  rowToDelete,
  currentData,
  getTrafficSourceId,
  getRedirectDomainId,
  getEntryDomainId,
  showArchive,
}) {
  const {
    addOffer,
    editOffer,
    editBulkOffer,
    deleteOffer,
    importOffer,
    bulkArchiveOffer,
    specificEditOffer,
    getAllOffersForIronDome
  } = useOffer();
  const { traffic, domains } = useContext(OfferContext);

  const onAddOffer = async (newOffer, handleClose, resetValues) => {
    const offer = await addOffer().call(newOffer);
    resetValues({
      offerLink: '',
      trafficSourceId: '',
      name: '',
      fallBackUrl: '',
      redirectDomainId: '',
      entryDomainId: '',
    });
    onAddOfferDone(offer);
    handleClose(true);
  };

  const onEditOffer = async (editField) => {
    const offer = await specificEditOffer().call(selectedRow._id, editField);
    onEditOfferDone(offer);
  };

  const onEditBulkOffer = async (payload) => {
    await editBulkOffer().call(payload);
    onEditBulkOfferDone();
  };

  const onDeleteOffer = async (id, handleClose) => {
    await deleteOffer().call(id);
    onDeleteOfferDone(id);
    handleClose(true);
  };

  const handleBulkArchive = async (initialData, archive) => {
    const offer = await bulkArchiveOffer().call(initialData, archive);
    onBulkArchiveDone(offer.data.updatedOffers);
  };

  const onDownloadTemplate = () => {
    const templateData = [
      ['Name', 'Offer link', 'Redirect link', 'Entry domain', 'Traffic source'],
    ];

    const csvContent = templateData.map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'offerTemplate.csv';

    link.click();

    URL.revokeObjectURL(link.href);
  };

  const onImportOffer = async (data) => {
    if (data.length <= 1) {
      window.alert('No data rows found in CSV. Import canceled.');
      return;
    }
  
    const headers = data[0];
    const requiredHeaders = [
      'Name',
      'Offer link',
      'Redirect link',
      'Entry domain',
      'Traffic source',
    ];
  
    const missingHeaders = requiredHeaders.filter(
      (header) => !headers.includes(header)
    );
    if (missingHeaders.length > 0) {
      window.alert(
        `Missing required header(s): ${missingHeaders.join(', ')}. Import canceled.`
      );
      return;
    }
  
    const formattedData = [];
  
    const headerIndexMap = requiredHeaders.reduce((acc, header) => {
      acc[header] = headers.indexOf(header);
      return acc;
    }, {});
  
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
  
      if (!row || row.length <= 1 || row.every((cell) => !cell)) {
        continue;
      }
  
      const nameCell = row[headerIndexMap['Name']];
      const offerLinkCell = row[headerIndexMap['Offer link']];
      const redirectLinkCell = row[headerIndexMap['Redirect link']];
      const entryDomainCell = row[headerIndexMap['Entry domain']];
      const trafficSourceCell = row[headerIndexMap['Traffic source']];
  
      if (
        !nameCell ||
        !offerLinkCell ||
        !redirectLinkCell ||
        !entryDomainCell ||
        !trafficSourceCell
      ) {
        window.alert(`Row ${i + 1} has missing data. Import canceled.`);
        return;
      }
  
      const redirectDomainId = getRedirectDomainId(redirectLinkCell);
      const entryDomainId = getEntryDomainId(entryDomainCell);
      const trafficSourceId = getTrafficSourceId(trafficSourceCell);
  
      if (!redirectDomainId) {
        window.alert(
          `Row ${i + 1} - Redirect domain does not exist for "${redirectLinkCell}". Import canceled.`
        );
        return;
      }
      if (!entryDomainId) {
        window.alert(
          `Row ${i + 1} - Entry domain does not exist for "${entryDomainCell}". Import canceled.`
        );
        return;
      }
      if (!trafficSourceId) {
        window.alert(
          `Row ${i + 1} - Traffic source does not exist for "${trafficSourceCell}". Import canceled.`
        );
        return;
      }
  
      formattedData.push({
        name: nameCell,
        offerLink: offerLinkCell,
        redirectDomainId,
        entryDomainId,
        trafficSourceId,
      });
    }
  
    if (formattedData.length === 0) {
      window.alert('No valid rows found to import. Import canceled.');
      return;
    }
  

    try {
      await importOffer().call(formattedData);
      onImportOfferDone();
      window.alert('CSV data successfully processed.');
    } catch (error) {
      console.error('Error importing offer:', error);
      window.alert('An error occurred while importing. Please check the console.');
    }
  };

  const onExportAll = async () => {
    try {
      const response = await getAllOffersForIronDome().call(); 
      const offers = response.offers || response;
      return offers
    } catch (error) {
      console.error('Error exporting all offers:', error);
      window.alert('Failed to export all offers.');
    }
  };
  

  const modalButtons = useMemo(() => {
    const res = [
      {
        name: 'add',
        label: 'Add',
        renderIf: true,
        icon: <AddIcon />,
        render: (open, handleClose) => {
          return (
            <AddOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onAddOffer}
            />
          );
        },
      },
      {
        name: 'edit',
        label: 'Edit',
        renderIf: Boolean(selectedRow),
        icon: <EditIcon />,
        render: (open, handleClose) => {
          return (
            <EditOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onEditOffer}
              initialData={selectedRow}
            />
          );
        },
      },
      {
        name: 'bulkEdit',
        label: 'Bulk Edit Offers',
        renderIf: Boolean(multipleDataSelected),
        icon: <EditIcon />,
        render: (open, handleClose) => {
          return (
            <BulkEditOffer
              open={open}
              handleClose={handleClose}
              filteredOffers={filteredOffers}
              onEditBulkOffer={onEditBulkOffer}
            />
          );
        },
      },
      {
        name: 'test',
        label: 'Test',
        renderIf: true,
        icon: <BuildIcon />,
        render: (open, handleClose) => {
          return <TestOffer open={open} handleClose={handleClose} />;
        },
      },
      {
        name: 'delete',
        label: 'Delete',
        renderIf: Boolean(rowToDelete.length > 0),
        icon: <DeleteIcon />,
        render: (open, handleClose) => {
          return (
            <DeleteOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onDeleteOffer}
              initialData={rowToDelete}
            />
          );
        },
      },
      {
        name: 'archive',
        label: 'Bulk Archive/Unarchive',
        renderIf: rowToArchive.length > 1,
        icon: <ArchiveIcon />,
        render: (open, handleClose) => {
          return (
            <ArchiveOffer
              open={open}
              handleClose={handleClose}
              onSubmit={handleBulkArchive}
              initialData={rowToArchive}
              showArchive={showArchive}
            />
          );
        },
      },
    ];

    return res;
  }, [selectedRow, rowToDelete]);

  const exportOptions = {
    name: 'Name',
    offerLink: 'Offer link',
    fallBackUrl: {
      label: 'Fallback URL',
    },
    redirectLink: {
      label: 'Redirect link',
    },
    entryDomain: {
      label: 'Entry domain',
    },
    trafficSource: {
      label: 'Traffic source',
    },
    archive: {
      label: 'Archive status',
    },
  };

  return (
    <DynamicToolbar
      modalButtons={modalButtons}
      allowExport
      allowImport
      exportFileName="offerTable.csv"
      rawExportData={currentData}
      exportOptions={exportOptions}
      onImportOffer={onImportOffer}
      onDownloadTemplate={onDownloadTemplate}
      allowExportAll={true}    
      onExportAll={onExportAll}
    />
  );
}

export default memo(OfferToolbar);
