import {
  TextField,
  MenuItem,
  Box,
  IconButton,
  SelectChangeEvent,
  Typography,
  Divider,
} from '@mui/material';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Delete, Add } from '@mui/icons-material';
import { nanoid } from 'nanoid';

type CustomOfferField = {
  id: string;
  key: string;
  label: string;
  type: string;
  dropdownOptions: {
    id: string;
    key: string;
    label: string;
  }[];
};

type Props = {
  initialValues?: CustomOfferField[];
  label: string;
};

const defaultFields: CustomOfferField[] = [
  {
    id: nanoid(),
    key: 'merchantId',
    label: 'M. Id',
    type: 'string',
    dropdownOptions: [],
  },

  {
    id: nanoid(),
    key: 'merchantName',
    label: 'M. Name',
    type: 'string',
    dropdownOptions: [],
  },

  {
    id: nanoid(),
    key: 'affiliateUrl',
    label: 'Aff. Url',
    type: 'string',
    dropdownOptions: [],
  },
  {
    id: nanoid(),
    key: 'commission',
    label: 'Comm.',
    type: 'currency',
    dropdownOptions: [],
  },
  {
    id: nanoid(),
    key: 'status',
    label: 'Status',
    type: 'dropdown',
    dropdownOptions: [
      {
        id: nanoid(),
        key: 'active',
        label: 'Active',
      },
      {
        id: nanoid(),
        key: 'inactive',
        label: 'Inactive',
      },
    ],
  },
];

const nonEditableFields = [
  'affiliateUrl',
  'commission',
  'merchantId',
  'merchantName',
  'status',
];

const booleanOptions = [
  { id: nanoid(), key: 'true', label: 'True' },
  { id: nanoid(), key: 'false', label: 'False' },
];

const GroupFieldSelector = React.forwardRef(
  ({ initialValues, label }: Props, ref) => {
    const [fields, setFields] = useState<CustomOfferField[]>([]);

    const onChangeField = (
      id: string,
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const { name, value } = e.target;

      const newFields = fields.map((f) => {
        if (f.id !== id) return f;

        const updatedField = {
          ...f,
          [name]: value,
        };

        if (name === 'type' && value === 'boolean') {
          updatedField.dropdownOptions = booleanOptions;
        }

        return updatedField;
      });

      setFields(newFields);
    };

    const onChangeDropdown = (
      parentId: string,
      optionId: string,
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      const matchField = fields.find((item) => item.id === parentId);

      if (!matchField) return;

      const newOptions = matchField.dropdownOptions.map((item) => {
        if (item.id !== optionId) return item;

        return {
          ...item,
          [name]: value,
        };
      });

      const newField = {
        ...matchField,
        dropdownOptions: newOptions,
      };

      const newFields = fields.map((item) => {
        if (item.id !== parentId) return item;

        return newField;
      });

      setFields(newFields);
    };

    const addField = () => {
      const newField = {
        id: nanoid(),
        key: '',
        label: '',
        type: '',
        dropdownOptions: [],
      };

      setFields([...fields, newField]);
    };

    const deleteField = (id: string) => {
      const newFields = fields.filter((i) => i.id !== id);
      setFields(newFields);
    };

    const addDropdownOption = (fieldId: string) => {
      const matchField = fields.find((item) => item.id === fieldId);

      if (!matchField) return;

      const { dropdownOptions = [] } = matchField;

      const newOptions = [
        {
          id: nanoid(),
          key: '',
          label: '',
        },
        ...dropdownOptions,
      ];

      const newField = {
        ...matchField,
        dropdownOptions: newOptions,
      };

      const newFields = fields.map((item) => {
        if (item.id !== fieldId) return item;

        return newField;
      });

      setFields(newFields);
    };

    const deleteDropdownOption = (fieldId: string, optionId: string) => {
      const matchField = fields.find((f) => f.id === fieldId);

      if (!matchField) return;

      const newOptions = matchField.dropdownOptions.filter(
        (d) => d.id !== optionId
      );

      const newField = {
        ...matchField,
        dropdownOptions: newOptions,
      };

      const newFields = fields.map((item) => {
        if (item.id !== fieldId) return item;

        return newField;
      });

      setFields(newFields);
    };

    const initHandle = () => {
      return {
        getFields: () => fields,
      };
    };

    useEffect(() => {
      if (!initialValues) return setFields(defaultFields.slice());

      const withDefaults = [...initialValues, ...defaultFields];
      const map = new Map<string, CustomOfferField>();

      for (const item of withDefaults) {
        const exist = map.get(item.key);

        if (!exist) map.set(item.key, item);
      }

      const newFields = Array.from(map.values());

      setFields(newFields);
    }, [initialValues]);

    useImperativeHandle(ref, initHandle, [fields]);

    return (
      <>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">{label}</Typography>
          <Box>
            <IconButton onClick={addField}>
              <Add />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>
        {fields.map((field) => {
          const { label, key, type, id, dropdownOptions = [] } = field;

          const nonEditable = nonEditableFields.includes(key);
          const isDropdown = type === 'dropdown';
          const isBoolean = type === 'boolean';

          return (
            <>
              <Box display="flex" gap={1} alignItems="center">
                <TextField
                  size="small"
                  label="Key"
                  value={key}
                  onChange={(e) => onChangeField(id, e)}
                  name="key"
                  sx={{ flexGrow: 1 }}
                  fullWidth
                  margin="normal"
                  disabled={nonEditable}
                />
                <TextField
                  size="small"
                  label="Label"
                  value={label}
                  onChange={(e) => onChangeField(id, e)}
                  name="label"
                  sx={{ flexGrow: 1 }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  size="small"
                  select
                  label="Type"
                  value={type}
                  onChange={(e) => onChangeField(id, e)}
                  name="type"
                  sx={{ flexGrow: 1 }}
                  fullWidth
                  margin="normal"
                  disabled={nonEditable}
                >
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="currency">Currency</MenuItem>
                  <MenuItem value="dropdown">Dropdown</MenuItem>
                  <MenuItem value="boolean">Boolean</MenuItem>
                </TextField>
                <IconButton
                  onClick={() => deleteField(id)}
                  disabled={nonEditable}
                >
                  <Delete />
                </IconButton>
              </Box>
              {isDropdown && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Typography variant="h6">Add Option</Typography>
                    <Box>
                      <IconButton
                        onClick={() => addDropdownOption(id)}
                        disabled={nonEditable}
                      >
                        <Add />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider sx={{ mb: 2 }}></Divider>
                  {dropdownOptions.map((item) => {
                    return (
                      <Box display="flex" gap={1} mb={2} alignItems="center">
                        <TextField
                          size="small"
                          onChange={(e) => onChangeDropdown(id, item.id, e)}
                          name="key"
                          label="Key"
                          value={item.key}
                          sx={{ flexGrow: 1 }}
                          disabled={nonEditable}
                        />
                        <TextField
                          size="small"
                          onChange={(e) => onChangeDropdown(id, item.id, e)}
                          name="label"
                          label="Label"
                          value={item.label}
                          sx={{ flexGrow: 1 }}
                          disabled={nonEditable}
                        />
                        <IconButton
                          onClick={() => deleteDropdownOption(id, item.id)}
                          disabled={nonEditable}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    );
                  })}
                </>
              )}
              {isBoolean && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Typography variant="h6">Boolean Options</Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }}></Divider>
                  {booleanOptions.map((item) => {
                    return (
                      <Box display="flex" gap={1} mb={2} alignItems="center">
                        <TextField
                          size="small"
                          name="key"
                          label="Key"
                          value={item.key}
                          sx={{ flexGrow: 1 }}
                          disabled
                        />
                        <TextField
                          size="small"
                          name="label"
                          label="Label"
                          value={item.label}
                          sx={{ flexGrow: 1 }}
                          disabled
                        />
                      </Box>
                    );
                  })}
                </>
              )}
            </>
          );
        })}
      </>
    );
  }
);

export default GroupFieldSelector;