import React, { useState, useMemo, useEffect, useContext, useRef } from 'react';
import {
  Card,
  Box,
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import OfferTableToolbar from './Toolbar';
import useOffer from '../useOffer';
import { OfferContext } from '../context';
import TooltipIconButton from '../../../components/TooltipIconButton';

import { Link } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QuickFilter from '../../../components/QuickFilter';

function OfferTable() {
  const { getOffers, editOffer, specificEditOffer } = useOffer();
  const [trafficMap, setTrafficMap] = useState(null);
  const [offers, setOffers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(undefined);
  const [multipleDataSelected, setMultipleDataSelected] = useState(false);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [quickFilter, setQuickFilter] = useState('');
  const [filters, setFilters] = useState({
    name: [],
    offerLink: [],
    trafficSourceId: [],
  });
  const [showArchive, setShowArchive] = useState(false);
  const [finalOffers, setFinalOffers] = useState([]);
  const gridApi = useRef(null);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sortModel, setSortModel] = useState({
    sortColumn: null,
    sort: null,
  });
  const [filterModel, setFilterModel] = useState({});
  const prevPageRef = useRef(pagination.page);

  const {
    traffic,
    loading: contextLoading,
    domains,
  } = useContext(OfferContext);

  const handleShowArchiveChange = (event) => {
    setShowArchive(event.target.checked);
  };




  const onEventDone = () => {
    init(
      pagination.page + 1,
      pagination.pageSize,
      sortModel.sortColumn,
      sortModel.sort,
      filterModel
    );
  }

  // useEffect(() => {
  //   if (offers.length > 0 && trafficMap) {
  //     const res = offers.filter((item) => {
  //       return Object.entries(filters).every(([key, value]) => {
  //         if (!(key in item)) {
  //           return (item[key] = '');
  //         }
  //         if (key === 'trafficSourceId') {
  //           if (item[key] === '') {
  //             return value.includes(item[key]);
  //           }
  //           const trafficSourceName = trafficMap[item[key]]?.name || '';
  //           return value.includes(trafficSourceName);
  //         } else {
  //           return value.includes(item[key]);
  //         }
  //       });
  //     });

  //     const filteredWithQuickFilter = res.filter((item) => {
  //       const lowercasedFilter = quickFilter.toLowerCase();

  //       const nameMatches = item.name.toLowerCase().includes(lowercasedFilter);
  //       const offerLinkMatches = item.offerLink
  //         .toLowerCase()
  //         .includes(lowercasedFilter);
  //       const trafficSourceName = trafficMap[item.trafficSourceId]?.name || '';
  //       const trafficSourceMatches = trafficSourceName
  //         .toLowerCase()
  //         .includes(lowercasedFilter);

  //       let redirectLink = '';
  //       if (item.redirectDomainId) {
  //         const domainData = domainMap[item.redirectDomainId];
  //         if (domainData && domainData.domainLink) {
  //           try {
  //             const domainObject = new URL(domainData.domainLink);
  //             redirectLink = `${domainObject.origin}/r/${item.uniqueId}`;
  //           } catch (error) {
  //             console.error('Invalid URL for redirect link:', error);
  //           }
  //         }
  //       }
  //       const redirectLinkMatches = redirectLink
  //         .toLowerCase()
  //         .includes(lowercasedFilter);

  //       return (
  //         nameMatches ||
  //         offerLinkMatches ||
  //         trafficSourceMatches ||
  //         redirectLinkMatches
  //       );
  //     });

  //     const sortedArray = filteredWithQuickFilter.sort(
  //       (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //     );

  //     setFilteredOffers(sortedArray);
  //   }
  // }, [offers, trafficMap, filters, quickFilter]);

  const updateOfferArchiveStatus = (id, newArchiveStatus) => {
    const newArchiveStatusItem = { archive: newArchiveStatus };
    const updatedOffers = offers.map((item) => {
      if (item._id === id) {
        const updatedOffer = { ...item, archive: newArchiveStatus };
        specificEditOffer().call(id, newArchiveStatusItem);
        return updatedOffer;
      }
      return item;
    });
    setOffers(updatedOffers);
  };

  const renderCell = (type, data) => {
    switch (type) {
      case 'name':
        return data[type];
      case 'offerLink':
        return data[type];
      case 'fallBackUrl':
        return data[type];
      case 'redirectLink':
        if (data[type]) {
          return data[type];
        } else {
          return <Chip color="error" label="Domain invalid" />;
        }
      case 'entryDomain':
        if (data[type]) {
          return data[type];
        } else {
          return <Chip color="error" label="No entry domain" />;
        }
      case 'trafficSource':
        const trafficSourceId = data.trafficSourceId || '';

        if (!trafficSourceId)
          return <Chip label="No traffic source" color="warning" />;

        const label = data[type];

        if (!label) return <Chip label="No traffic source" color="warning" />;

        return (
          <Link
            to={{
              pathname: `/traffic/${trafficSourceId}`,
              state: { trafficSourceId },
            }}
          >
            <Chip label={label} color="info" sx={{ cursor: 'pointer' }} />
          </Link>
        );
      case '_id':
        const redirectDomainId2 = data.redirectDomainId || '';
        const domainData2 = domainMap[redirectDomainId2];
        let openLink = ``;
        let clipboardText = ``;

        try {
          const domainObject = new URL(domainData2.domainLink);
          clipboardText = domainObject.origin + `/r/${data.uniqueId}`;
          openLink = domainObject.origin + `/r/${data.uniqueId}`;
        } catch (error) { }

        const handleArchiveToggle = () => {
          const newArchiveStatus = !data.archive;
          updateOfferArchiveStatus(data._id, newArchiveStatus);
        };

        return (
          <Box>
            <TooltipIconButton
              icon={<ContentCopyIcon />}
              title="Copy redirect link"
              onClick={() => copyToClipboard(clipboardText)}
              size="small"
              disabled={!clipboardText}
            />
            <Tooltip title="Open redirect link">
              <a href={openLink} target=" _blank" rel="noreferrer noopenner">
                <IconButton>
                  <OpenInNewIcon />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title={data.archive ? 'Unarchive' : 'Archive'}>
              <IconButton onClick={handleArchiveToggle}>
                {data.archive ? <UnarchiveIcon /> : <ArchiveIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        );

      default:
        return data[type];
    }
  };

  // useEffect(() => {
  //   if (offers.length > 0 && trafficMap) {
  //     tableCols.map((item) => {
  //       if (item['filter']) {
  //         const res = Array.from(
  //           new Set(
  //             offers
  //               .filter(
  //                 (c) => c[item.field] !== null && c[item.field] !== undefined
  //               )
  //               .map((e) => {
  //                 if (item.renderFilter) {
  //                   return item.renderFilter(e[item.field]);
  //                 } else {
  //                   return e[item.field];
  //                 }
  //               })
  //           )
  //         );
  //         setFilters((prevFilters) => ({
  //           ...prevFilters,
  //           [item.field]: res,
  //         }));
  //       }
  //     });
  //   }
  // }, [offers, trafficMap]);

  const domainMap = useMemo(() => {
    return domains.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
  }, [domains]);

  const init = (page = 1, limit = 20, sortColumn = null, sort = null, filterModel = {}) => {
    const apiCall = getOffers();
    (async function () {
      try {
        setLoading(true);
        const res = await apiCall.call(page, limit, sortColumn, sort, filterModel);
        formatOfferData(res.offers);
        setTotalRowCount(res.totalCount);
        setLoading(false);
      } catch (e) {
        console.error('Error fetching offers:', e);
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };



  useEffect(() => {
    if (!domainMap || !trafficMap) {
      return
    }
    else {
      init(
        pagination.page + 1,
        pagination.pageSize,
        sortModel.sortColumn,
        sortModel.sort,
        filterModel
      );
    }

  }, [filterModel, pagination.page, pagination.pageSize, sortModel, trafficMap, domainMap]);

  const formatOfferData = (offers, usingFunction = 'default') => {

    offers.forEach((offer, index) => {
      const redirectDomainId = offer.redirectDomainId || '';
      const domainData = domainMap[redirectDomainId];
      let redirectLink = '';
      if (domainData && domainData.domainLink) {
        try {
          const domainObject = new URL(domainData.domainLink);
          redirectLink = domainObject.origin + `/r/${offer.uniqueId}`;
        } catch (error) {
          redirectLink = 'Domain invalid';
        }
      }
      offers[index].redirectLink = redirectLink;

      const entryDomainId = offer.entryDomainId || '';
      const entryDomainData = domainMap[entryDomainId];
      let entryDomain = '';
      if (entryDomainData && entryDomainData.domainLink) {
        entryDomain = entryDomainData.domainLink + `/offer/${offer.uniqueId}`;
      }
      offers[index].entryDomain = entryDomain;

      const trafficSourceId = offer.trafficSourceId || '';
      const trafficSourceData = trafficMap[trafficSourceId];
      offers[index].trafficSource = trafficSourceData ? trafficSourceData.name : '';
    });

    if (usingFunction !== 'default') {
      setFilteredOffers(offers);
    } else {
      setOffers(offers);
    }
  };

  const gettingFinalOffers = () => {
    let finalOffer;
    if (showArchive) {
      finalOffer = offers;
    } else {
      finalOffer = offers.filter((offer) => offer.archive === false || offer.archive === undefined);
    }
    setFinalOffers(finalOffer);
  };
  useEffect(() => {
    gettingFinalOffers();
  }, [showArchive, offers]);

  useEffect(() => {
    const res = traffic.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
    setTrafficMap(res);
  }, [traffic]);

  const getTrafficSourceId = (val) => {
    if (typeof val !== 'string') return '';
    const trimmedVal = val.trim();
    for (const trafficSourceId in trafficMap) {
      if (trafficMap.hasOwnProperty(trafficSourceId)) {
        const trafficSource = trafficMap[trafficSourceId];
        if (trafficSource?.name?.trim() === trimmedVal) {
          return trafficSourceId;
        }
      }
    }
    return '';
  };
  const getRedirectDomainId = (val) => {
    let trimmedVal;

    if (val.includes('/r/')) {
      trimmedVal = val.split('/r/')[0];
    } else {
      trimmedVal = val;
    }

    for (const domainId in domainMap) {
      if (domainMap.hasOwnProperty(domainId)) {
        const domain = domainMap[domainId];
        if (domain.domainLink === trimmedVal) {
          return domainId;
        }
      }
    }

    return '';
  };

  const getEntryDomainId = (val) => {
    for (const entryId in domainMap) {
      if (domainMap.hasOwnProperty(entryId)) {
        const entry = domainMap[entryId];
        if (entry.domainLink === val) {
          return entryId;
        }
      }
    }

    return '';
  };

  const handlePageSizeChange = (event) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: Number(event.target.value),
      page: 0,
    }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const onSortChanged = () => {
    if (gridApi.current) {
      const sortState = gridApi.current
        .getColumnState()
        .filter((col) => col.sort !== null && col.sort !== undefined);
      if (sortState.length > 0) {
        setSortModel({
          sortColumn: sortState[0].colId,
          sort: sortState[0].sort,
        });
      } else {
        setSortModel({ sortColumn: null, sort: null });
      }
    }
  };

  const onFilterChanged = () => {
    const newFilterModel = gridApi.current.getFilterModel();


    if (Object.keys(newFilterModel).length > 0) {

      if (pagination.page !== 0) {
        prevPageRef.current = pagination.page;
        setPagination(prev => ({ ...prev, page: 0 }));
      }
    } else {
      setPagination(prev => ({ ...prev, page: prevPageRef.current }));
    }
    setFilterModel(newFilterModel);
  };

  useEffect(() => {
    if (selectedRows.length > 1) {
      setSelectedRowData(undefined);
    } else {
      setSelectedRowData(offers.find((item) => item._id === selectedRows[0]));
    }
  }, [selectedRows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [offers]);

  const generateTableCols2 = (trafficMap, domainMap, contextLoading) => {
    const tableCols = [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        filter: 'agTextColumnFilter',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellRenderer: (params) => renderCell('name', params.data),
      },
      {
        field: 'offerLink',
        headerName: 'Original Offer Link',
        flex: 3,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => renderCell('offerLink', params.data),
      },
      {
        field: 'fallBackUrl',
        headerName: 'Fallback URL',
        flex: 3,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => renderCell('fallBackUrl', params.data),
      },
      {
        field: 'redirectLink',
        headerName: 'Redirect Link',
        flex: 3,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => renderCell('redirectLink', params.data),
      },
      {
        field: 'entryDomain',
        headerName: 'Entry Domain',
        flex: 3,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => renderCell('entryDomain', params.data),
      },
      {
        field: 'trafficSource',
        headerName: 'Traffic Source',
        flex: 2,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => renderCell('trafficSource', params.data),
        valueGetter: (params) => params.data.trafficSource,
      },
      {
        field: 'archive',
        headerName: 'Archive Status',
        flex: 2,
        filter: 'agTextColumnFilter',
        floatingFilter: false,
        cellRenderer: (params) => {
          const isArchived = params.data.archive;
          return (
            <Chip
              label={`${isArchived == undefined ? false : isArchived}`}
              color={isArchived ? 'success' : 'error'}
            />
          );
        },
        valueGetter: (params) => params.data.archive,
      },
      {
        field: '_id',
        headerName: 'Actions',
        flex: 2,
        filter: false,
        sortable: false,
        cellRenderer: (params) => renderCell('_id', params.data),
      },
    ];

    return tableCols;
  };

  const tableCols = generateTableCols2(trafficMap, domainMap, contextLoading);

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <OfferTableToolbar
          onAddOfferDone={onEventDone}
          onEditOfferDone={onEventDone}
          selectedRow={selectedRowData}
          multipleDataSelected={multipleDataSelected}
          filteredOffers={filteredOffers}
          onImportOfferDone={onEventDone}
          onDeleteOfferDone={onEventDone}
          onBulkArchiveDone={onEventDone}
          onEditBulkOfferDone={onEventDone}
          rowToDelete={selectedRows}
          rowToArchive={selectedRows}
          currentData={offers}
          getTrafficSourceId={getTrafficSourceId}
          getRedirectDomainId={getRedirectDomainId}
          getEntryDomainId={getEntryDomainId}
          showArchive={showArchive}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showArchive}
              onChange={handleShowArchiveChange}
              name="showArchived"
              color="primary"
            />
          }
          label="Show Archived"
        />
      </Box>
      {/* <QuickFilter quickFilter={quickFilter} setQuickFilter={setQuickFilter} /> */}
      <Box
        sx={{
          width: '100%',
          height: '1000px',
        }}
        className="ag-theme-alpine"
      >
        <Box position="relative" height="100%">
          <AgGridReact
            rowData={finalOffers}
            columnDefs={tableCols}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              flex: 1,
            }}
            enableCellTextSelection={true}
            rowSelection="multiple"
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            pagination={false}
            onGridReady={(params) => {
              gridApi.current = params.api;
            }}
            onSelectionChanged={(event) => {
              const selectedNodes = event.api.getSelectedNodes();
              const selectedData = selectedNodes.map((node) => node.data);
              setSelectedRows(selectedData.map((data) => data._id));

              const newSelectedData =
                selectedData.length === 1 ? selectedData[0] : undefined;

              if (selectedData.length > 1) {
                setMultipleDataSelected(true)
              } else {
                setMultipleDataSelected(false)
              }

              formatOfferData(selectedData, 'agGrid');
              setSelectedRowData(newSelectedData);
            }}
          />
          {loading && (
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="rgba(255, 255, 255, 0.7)"
              zIndex={1}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center" ml={2}>
          <Box component="span" sx={{ mr: 1 }}>
            Page {pagination.page + 1} of{' '}
            {Math.ceil(totalRowCount / pagination.pageSize)} | Total offers: {totalRowCount}
          </Box>
          <Select
            value={pagination.pageSize}
            onChange={handlePageSizeChange}
            size="small"
            sx={{ mr: 2 }}
          >
            {[10, 20, 30, 50, 100, 200].map((size) => (
              <MenuItem key={size} value={size}>
                {size} per page
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePageChange(Math.max(0, pagination.page - 1))}
          disabled={pagination.page === 0}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePageChange(pagination.page + 1)}
          disabled={(pagination.page + 1) * pagination.pageSize >= totalRowCount}
          sx={{ ml: 2 }}
        >
          Next
        </Button>
      </Box>
    </Card>
  );
}

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export default OfferTable;
