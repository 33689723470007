import useApi from '../../hooks/useApi';

const API_URL = '/api/offers';
const API_URL_BULK = '/api/offers/bulk';

export default function useOffer() {
  const { api: apiClient, createApiCall } = useApi();

  const getOffers = createApiCall(
    async ({ signal }, page, limit, sortColumn, sort, filterModel) => {
      const params = {};

      if (page && limit) {
        params.page = page;
        params.limit = limit;
      }
      if (sortColumn && sort) {
        params.sortColumn = sortColumn;
        params.sort = sort;
      }
      if (filterModel && Object.keys(filterModel).length > 0) {
        params.filter = JSON.stringify(filterModel);
      }

      const response = await apiClient.get(API_URL, { params, signal });
      const offers = response.data.offers;

      if (page && limit) {
        const totalCount =
          response.data.totalCount !== undefined
            ? response.data.totalCount
            : offers.length;
        return { offers, totalCount };
      }
      return offers;
    }
  );

  const getAllOffersForIronDome = createApiCall(async ({ signal }) => {
    const {
      data: { offers },
    } = await apiClient.post(`${API_URL}/iron-dome`, { signal });

    return offers;
  });

  const editOffer = createApiCall(
    async ({ signal }, { offerId, editField }) => {
      const {
        data: { offer },
      } = await apiClient.patch(`${API_URL}/${offerId}`, editField, { signal });

      return offer;
    }
  );

  const checkOfferIfExist = createApiCall(async ({ signal }, uniqueId) => {
    const {
      data: { offer },
    } = await apiClient.get(`${API_URL}/by-uid/${uniqueId}`, {
      signal,
    });

    return offer;
  });

  const specificEditOffer = createApiCall(
    async ({ signal }, offerId, editField) => {
      const {
        data: { offer },
      } = await apiClient.patch(`${API_URL}/${offerId}`, editField, { signal });

      return offer;
    }
  );

  const editBulkOffer = createApiCall(async ({ signal }, bulkEditOffer) => {
    const data = await apiClient.put(
      `${API_URL}/bulk-edit`,
      { bulkEditOffer },
      {
        signal,
      }
    );
    return data;
  });

  const deleteOffer = createApiCall(async ({ signal }, offerId) => {
    await apiClient.delete(`${API_URL}/${offerId}`, { signal });
  });

  const addOffer = createApiCall(async ({ signal }, newOffer) => {
    const {
      data: { offer },
    } = await apiClient.post(API_URL, newOffer, { signal });
    return offer;
  });

  const importOffer = createApiCall(async ({ signal }, newOffer) => {
    const {
      data: { offers },
    } = await apiClient.post(API_URL_BULK, { offers: newOffer }, { signal });
    return offers;
  });

  const bulkArchiveOffer = createApiCall(async ({ signal }, ids, archive) => {
    const data = await apiClient.put(
      `${API_URL}/bulk-archive`,
      { ids, archive },
      { signal }
    );

    return data;
  });

  const bulkEditRules = createApiCall(
    async ({ signal }, { offerIds, editFields }) => {
      const bulkUpdates = offerIds.map((offerId) => ({
        updateOne: {
          filter: { _id: offerId },
          update: { $set: editFields[offerId] },
        },
      }));

      const {
        data: { offers },
      } = await apiClient.put(
        `${API_URL}/bulk-edit-rules`,
        { bulkUpdates },
        { signal }
      );

      return offers;
    }
  );

  return {
    addOffer,
    bulkEditRules,
    getOffers,
    editOffer,
    deleteOffer,
    importOffer,
    bulkArchiveOffer,
    specificEditOffer,
    editBulkOffer,
    getAllOffersForIronDome,
    checkOfferIfExist,
  };
}
