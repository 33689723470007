import { createContext, useEffect, useState } from 'react';
import useOffer from '../offer/useOffer';

type Props = {
  children: React.ReactNode;
};

type IronDomeContextValues = {
  offers: any[];
  selectedMembers: any[];
  loading: boolean;
  setSelectedMembers: React.Dispatch<React.SetStateAction<any[]>>;
  setOffers: React.Dispatch<React.SetStateAction<any[]>>;
};

export const IronDomeContext = createContext<IronDomeContextValues>({
  offers: [],
  selectedMembers: [],
  loading: false,
  setSelectedMembers: {} as any,
  setOffers: {} as any,
});

const IronDomeProvider = ({ children }: Props) => {
  const [offers, setOffers] = useState<any[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const offerClient = useOffer();

  const values: IronDomeContextValues = {
    offers,
    selectedMembers,
    loading,
    setSelectedMembers,
    setOffers,
  };

  const initOffers = () => {
    const api = offerClient.getAllOffersForIronDome();

    (async () => {
      setLoading(true);
      try {
        const res = await api.call();
        setOffers(res);
      } catch (error) {}
      setLoading(false);
    })();

    return api.abort;
  };

  useEffect(() => {
    return initOffers();
  }, []);

  return (
    <IronDomeContext.Provider value={values}>
      {children}
    </IronDomeContext.Provider>
  );
};

export default IronDomeProvider;
