import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { getAxiosErrorMessage } from '../../../lib';
import useCampaign from '../useCampaign';
import { Campaign } from '../types';
import { BlockRule } from '../../traffic/types';
import RuleForm from '../../../components/RuleForm';

type Props = {};

const SingleCampaignBlock = (props: Props) => {
  const [campaignData, setCampaignData] = useState<Campaign | null>(null);
  const [loading, setLoading] = useState(false);

  const { getSingleCampaign } = useCampaign();

  const params = useParams();
  const navigate = useNavigate();
  const client = useCampaign();

  const init = (id: string) => {
    setLoading(true);
    const apiCall = getSingleCampaign();

    (async function () {
      try {
        const singleCampaign: Campaign = await apiCall.call(id);
        setCampaignData(singleCampaign);
      } catch (error) {
        console.error(getAxiosErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    if (!params.sourceId) return;

    return init(params.sourceId);
  }, [params.sourceId]);

  const editRule = async (rules: BlockRule[]) => {
    if (!campaignData) return;
    const params = { ...campaignData, blockRules: rules };
    await client.editCampaign().call(campaignData._id, params);

    setCampaignData(params);
  };

  const onDelete = async (rules: BlockRule[]) => {
    if (!campaignData) return;

    const newCampaign = { ...campaignData, blockRules: rules };

    await client.editCampaign().call(campaignData._id, newCampaign);

    setCampaignData(newCampaign);
  };

  const addEmptyRule = async (newRule: BlockRule) => {
    if (!campaignData) return;

    const flatRules = [campaignData.blockRules].flat();
    flatRules.push(newRule);

    const params = { ...campaignData, blockRules: flatRules };
    await client.editCampaign().call(campaignData._id, params);

    setCampaignData(params);
  };

  return (
    <Box my={4}>
      <Box mb={3}>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <Button startIcon={<ArrowBack />}>Back</Button>
        </Link>
      </Box>
      <Typography variant="h4" gutterBottom>
        Block Rules
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <RuleForm
          parentRules={campaignData?.blockRules ?? []}
          onDelete={onDelete}
          onSubmit={editRule}
          onAddNewRule={addEmptyRule}
        />
      )}
    </Box>
  );
};

export default SingleCampaignBlock;
