import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef } from 'ag-grid-community';
import { Box, CircularProgress, Paper, Chip } from '@mui/material';
import useClicks from '../../useClicks';
import moment from 'moment';
import RequestTableToolbarWithGrouping from '../ToolbarGrouping';
import { Range } from 'react-date-range';

function RequestSummary() {
  interface SummaryData {
    domainLink: string;
    totalClicks: number;
    blockedClicks: number;
    blockedByLocation: number | string;
    blockedByDataCenter: number | string;
    blockedByMaliciousIp: number | string;
    blockedByDetector: number | string;
    blockedByAnon: number | string;
    blockedByGlobalfallback: number | string;
    blockedByIpThrottling: number | string;
    [key: string]: any;
  }

  const [data, setData] = useState<SummaryData[]>([]);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [groupBy, setGroupBy] = useState<string[]>([]);
  const [click, setClick] = useState<any>([]);
  const client = useClicks();

  const getClickSummary = async () => {
    setLoading(true);
    try {
      const res = await client
        .getClicksRequest()
        .call(
          moment(dateRange.startDate).format('YYYY-MM-DD'),
          moment(dateRange.endDate).format('YYYY-MM-DD'),
          groupBy
        );

      const summaryData = res.clicksStats.summary.map((item: any) => ({
        ...item,
        blockedByLocation: item.blockedClicks
          ? ((item.blockedByLocation / item.totalClicks) * 100).toFixed(2) +
            '%'
          : '0.00%',
        blockedByDataCenter: item.blockedClicks
          ? ((item.blockedByDataCenter / item.totalClicks) * 100).toFixed(2) +
            '%'
          : '0.00%',
        blockedByMaliciousIp: item.blockedClicks
          ? ((item.blockedByMaliciousIp / item.totalClicks) * 100).toFixed(
              2
            ) + '%'
          : '0.00%',
        blockedByDetector: item.blockedClicks
          ? ((item.blockedByDetector / item.totalClicks) * 100).toFixed(2) +
            '%'
          : '0.00%',
        blockedByAnon: item.blockedClicks
          ? ((item.blockedByAnon / item.totalClicks) * 100).toFixed(2) + '%'
          : '0.00%',
        blockedByIpThrottling: item.blockedClicks
          ? ((item.blockedByIpThrottling / item.totalClicks) * 100).toFixed(
              2
            ) + '%'
          : '0.00%',
      }));

      const totalSummary: { [key: string]: any } = {
        domainLink: '',
        totalClicks: res.clicksStats.totalClicks,
        blockedClicks: res.clicksStats.totalBlockedClicks,
        blockedByLocation: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByLocation /
                res.clicksStats.totalClicks) *
              100
            ).toFixed(2) + '%'
          : '0.00%',
        blockedByDataCenter: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByDataCenter /
                res.clicksStats.totalClicks) *
              100
            ).toFixed(2) + '%'
          : '0.00%',
        blockedByMaliciousIp: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByMaliciousIp /
                res.clicksStats.totalClicks) *
              100
            ).toFixed(2) + '%'
          : '0.00%',
        blockedByDetector: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByDetector /
                res.clicksStats.totalClicks) *
              100
            ).toFixed(2) + '%'
          : '0.00%',
        blockedByAnon: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByAnon /
                res.clicksStats.totalClicks) *
              100
            ).toFixed(2) + '%'
          : '0.00%',
        blockedByIpThrottling: res.clicksStats.totalBlockedClicks
          ? (
              (res.clicksStats.totalBlockedByIpThrottling /
                res.clicksStats.totalClicks) *
              100
            ).toFixed(2) + '%'
          : '0.00%',
      };

      groupBy.forEach((field) => {
        totalSummary[field] = '';
      });

      setData([totalSummary, ...summaryData]);
      setTotalRowCount(res.clicksStats.totalClicks);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getClickSummary();
    }
  }, [dateRange, groupBy]);

  const handleDateFilterSubmit = (ranges: Range[]) => {
    if (ranges && ranges.length > 0) {
      setDateRange(ranges[0]);
    }
  };

  const handleGroupByChange = (newGroupBy: string[]) => {
    setGroupBy(newGroupBy);
  };

  const customComparator = (
    valueA: any,
    valueB: any,
    nodeA: any,
    nodeB: any
  ) => {
    if (nodeA?.data?.domainLink === '' || nodeB?.data?.domainLink === '') {
      return 0;
    }
    return typeof valueA === 'number' && typeof valueB === 'number'
      ? valueA - valueB
      : valueA?.localeCompare(valueB);
  };

  const baseColumns: ColDef[] = [
    {
      field: 'domainLink',
      headerName: 'Domain Link',
      sortable: true,
      flex: 1,
      filter: true,
      floatingFilter: true,
      comparator: customComparator,
    },
  ];

  const headerNameMap: { [key: string]: string } = {
    trafficSource: 'Traffic Source',
    offer: 'Offer',
    date: 'Date',
  };

  const dynamicColumns: ColDef[] = groupBy.map((group) => ({
    field: group,
    headerName: headerNameMap[group] || group,
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    comparator: customComparator,
  }));

  const additionalColumns: ColDef[] = [
    {
      field: 'totalClicks',
      headerName: 'Total Clicks',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedClicks',
      headerName: 'Blk Clicks',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedByLocation',
      headerName: 'Blk Loc (%)',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedByDataCenter',
      headerName: 'Blk D.Center (%)',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedByMaliciousIp',
      headerName: 'Blk IP (%)',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedByDetector',
      headerName: 'Blk Ext(%)',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedByAnon',
      headerName: 'Blk Anon (%)',
      flex: 1,
      comparator: customComparator,
    },
    {
      field: 'blockedByIpThrottling',
      headerName: 'Blk IP Throtle(%)',
      flex: 1,
    },
  ];

  const columns = [...baseColumns, ...dynamicColumns, ...additionalColumns];

  return (
    <Box
      className="ag-theme-alpine"
      style={{ height: '700px', width: '100%' }}
      mt={20}
    >
      <Box display={'flex'} gap={1} alignItems={'center'} mb={2}>
        <Chip
          variant="outlined"
          color="primary"
          label={`Starts From: ${moment(dateRange.startDate).format(
            'YYYY-MM-DD'
          )}`}
        ></Chip>
        <Chip
          variant="outlined"
          color="primary"
          label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
        ></Chip>
      </Box>
      <RequestTableToolbarWithGrouping
        onDateFilterSubmit={handleDateFilterSubmit}
        initialDateRange={dateRange}
        onGroupByChange={handleGroupByChange}
        initialGroupBy={groupBy}
        exportData={data}
      />
      <Box position="relative" height="100%">
        <AgGridReact
          rowData={data}
          columnDefs={columns}
          pagination={true}
          paginationPageSize={20}
          paginationPageSizeSelector={[10, 20, 30, 50, 100, 200]}
        />
        {loading && (
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={1}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default RequestSummary;