import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { OfferGroupTraffic } from '../types';
import AddCustomOfferGroupTrafficSource from '../AddOfferGroupTrafficSource';

type Props = {
  onAddOfferGroupSourcesDone: (newData: OfferGroupTraffic) => any;
};

const OfferGroupTrafficSourcesToolbar = ({
  onAddOfferGroupSourcesDone,
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (newData: OfferGroupTraffic) => {
    await onAddOfferGroupSourcesDone(newData);
    handleClose();
  };

  return (
    <Box display="flex" gap={1}>
      <Button
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Add
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Traffic Source</DialogTitle>
        <DialogContent>
          <AddCustomOfferGroupTrafficSource
            open={open}
            handleClose={handleClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OfferGroupTrafficSourcesToolbar;