import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';

export default function EditTraffic({
  initialData,
  open,
  handleClose,
  onSubmit,
}) {
  const initialValues = {
    name: initialData.name,
    network: initialData.network || 'default',
    connectedDsp: initialData.connectedDsp || '',
    archive: initialData.archive || false,
  };

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (!open) {
      setValues(initialValues);
    }
  }, [open]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value === 'None' ? '' : value,
    });
  };

  const handleCancel = () => {
    setValues(initialValues);
    handleClose();
  };

  const handleSubmit = async () => {
    await onSubmit(values);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Traffic Source
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          size="small"
          name="name"
          value={values.name}
          onChange={onChange}
          fullWidth
          placeholder="Traffic source name"
          label="Name"
          margin="dense"
        />
        <TextField
          size="small"
          select
          name="network"
          label="Network"
          helperText="This affects how redirect works"
          onChange={onChange}
          value={values.network}
          fullWidth
          margin="dense"
        >
          <MenuItem value="default">Default</MenuItem>
          <MenuItem value="adnet">Adnet</MenuItem>
        </TextField>
        <TextField
          size="small"
          select
          name="connectedDsp"
          label="Connected DSP"
          onChange={onChange}
          helperText="This affects where the wins will be recorded"
          value={values.connectedDsp || 'None'}
          fullWidth
          margin="dense"
        >
          <MenuItem value="None">None</MenuItem>
          <MenuItem value="dsp24">DSP 24</MenuItem>
          <MenuItem value="rapid">Rapid</MenuItem>
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
