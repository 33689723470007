import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Autocomplete, TextField, Typography } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { IronDomeContext } from '../context';
import { BlockRule } from '../../traffic/types';
import useOffer from '../../offer/useOffer';
import { nanoid } from 'nanoid';
import { showMessage } from '../../../components/utils/showMessage';

type CloneOfferDialogProps = {
  open: boolean;
  onClose: () => void;
  onClone: (updatedOffers: any[]) => void;
  sourceOffer: any;
};

const CloneOfferDialog = ({ open, onClose, onClone, sourceOffer }: CloneOfferDialogProps) => {
  const { offers } = useContext(IronDomeContext);
  const offerClient = useOffer();
  const [targetOffers, setTargetOffers] = useState<any[]>([]);

  useEffect(() => {
    if (open) {
      setTargetOffers([]); 
    }
  }, [open]);

  const handleClone = async () => {
    if (targetOffers.length === 0) {
      showMessage({
        message: 'Please select at least one target offer.',
        severity: 'warning',
        duration: 3000,
      });
      return;
    }

    try {
      const updatedOffers = targetOffers.map((target: any) => {
        const clonedRules = sourceOffer.blockRules.map((rule: BlockRule) => ({
          ...rule,
          id: nanoid(),
        }));
        return {
          ...target,
          blockRules: clonedRules,
        };
      });

      const offerIds = targetOffers.map((offer: any) => offer._id);
      const editFields = Object.fromEntries(
        updatedOffers.map((offer: any) => [offer._id, { blockRules: offer.blockRules }])
      );

      await offerClient.bulkEditRules().call({ offerIds, editFields });

      onClone(updatedOffers);

      showMessage({
        message: 'Rules cloned successfully!',
        severity: 'success',
        duration: 3000,
      });

      setTargetOffers([]); 
      onClose();
    } catch (error) {
      console.error('Error cloning rules:', error);
      showMessage({
        message: 'Failed to clone rules. Please try again.',
        severity: 'error',
        duration: 3000,
      });
    }
  };

    const handleClose = () => {
    setTargetOffers([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Clone Offer Rules</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Cloning rules from: <strong>{sourceOffer?.name}</strong>
        </Typography>
        <Autocomplete
          multiple
          options={offers.filter((offer: any) => offer._id !== sourceOffer?._id)}
          getOptionLabel={(option) => option.name}
          value={targetOffers}
          onChange={(event, newValue) => setTargetOffers(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Target Offers" fullWidth margin="normal" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleClone} color="primary" variant="contained">
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloneOfferDialog;